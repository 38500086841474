import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProducPublicType, ProductItems } from "../typeProps/Productype";
import { Option } from "../types";

interface typeInitialState {
  isloadding: boolean;
  errorget: string | null;
  product: ProductItems[];
}
const initialState: typeInitialState = {
  isloadding: false,
  errorget: null,
  product: [],
};

export const producDetail = createSlice({
  name: "producDetail",
  initialState,
  reducers: {
    getProdutDetail(state, action: PayloadAction<number>) {
      state.isloadding = true;
    },
    getProdutDetailSucces(state, action: PayloadAction<ProductItems>) {
      state.product[0] = action.payload;
      state.isloadding = false;
    },
    getProdutDetailFail(state, action: PayloadAction<string>) {
      state.errorget = action.payload;
    },
  },
});

export const { getProdutDetail, getProdutDetailSucces, getProdutDetailFail } =
  producDetail.actions;

export default producDetail.reducer;
