import HttpService from "../configs/api";
import getApi from "./getApi";
import { listSearch, SearchParam } from "../typeProps/searchType";
const url = getApi("search");

const searchAllService = {
  getListSearch: (data: SearchParam): Promise<listSearch> => {
    return HttpService.axiosClient.get(`${url}/${data.type}/${data.param}`, {
      params: { page: data.option.page, limit: data.option.limit },
    });
  },
};

export default searchAllService;
