import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProducPublicType, productParam, productTypeState } from "../typeProps/Productype";
import { Option } from "../types";

interface productReferent {
  total: number;
  loadding: boolean;
  error: string | null;
  productList: ProducPublicType[];
  currentPage: number;
}

const initialState: productReferent = {
  total: 0,
  loadding: false,
  error: null,
  productList: [],
  currentPage: 1,
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    getProducts: (state, action: PayloadAction<Option>) => {
      state.loadding = true;
    },
    getProductSuccess(
      state,
      action: PayloadAction<{ total: number; list: ProducPublicType[] }>
    ) {
      const productList = action.payload;
      state.productList = productList.list;
      state.total = productList.total;
      state.loadding = false;
      state.error = null;
    },
    ProductFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    addProduct(state, action: PayloadAction<ProducPublicType>) {
      state.loadding = true;
      state.error = null;
    },
    addProductSuccess(state, action: PayloadAction<ProducPublicType>) {},
    editProduct(state, action: PayloadAction<ProducPublicType>) {
      state.error = null;
    },
    editProductSucces(state, action: PayloadAction<ProducPublicType>) {
      const newProduct = action.payload;
      const newState = state.productList.map((product, index) => {
        if (product.id === newProduct.id) {
          product = newProduct;
        }
        return product;
      });
      state.productList = newState;
      state.loadding = false;
      state.error = null;
    },
    putProducts(state, action: PayloadAction<number>) {
    },
    putProductSuccess(
      state,
      action: PayloadAction<number>
    ) {
      const newProducts = state.productList.map((item, index) => {
        if (item.id === action.payload) {
          item.priority = !item.priority;
        }
        return item;
      });
      state.productList = newProducts;
      state.error = null;
    },
    searchProducts(state,action:PayloadAction<productParam>) {
      state.loadding = true;
    },
    searchProductSuccess(state, action:PayloadAction<productTypeState>){
      state.total= action.payload.total;
      state.productList = action.payload.list;
      state.loadding=false;
      state.error = null;
    }
  },
});

export const {
  addProduct,
  addProductSuccess,
  getProducts,
  getProductSuccess,
  ProductFail,
  editProduct,
  editProductSucces,
  putProducts,
  putProductSuccess,
  setCurrentPage,
  searchProducts,
  searchProductSuccess,
} = productSlice.actions;

export default productSlice.reducer;
