import HttpService from "../configs/api"
import { Option, ResponsiveData, User } from "../types"
import getApi from "./getApi"


const userService = {
    create: (data:User)=> {
        const url = getApi("admin/user");
        return HttpService.axiosClient.post(url, data);
    },
    find: (option:Option):Promise<ResponsiveData<User>> => {
        const url = getApi("admin/users");

        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}})
    },
    validateEmail: (email:string):Promise<boolean> => {
        const url = getApi(`user/check/email/${email}`);

        return HttpService.axiosClient.get(url);
    },
    validateLogin: (login:string):Promise<boolean> => {
        const url = getApi(`user/check/login/${login}`);

        return HttpService.axiosClient.get(url);
    },
    getProfile: ():Promise<User> => {
        const url = getApi("user")
        return HttpService.axiosClient.get(url);
    },
    deleteUser: (login:string):Promise<boolean> => {
        const url = getApi(`admin/user/${login}`);

        return HttpService.axiosClient.delete(url);

    },
    searchUser: ({keyword, option}:{keyword:string, option:Option}):Promise<ResponsiveData<User>> => {
        const url = getApi(`admin/user/search/${keyword}`)

        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});

    },
    updateUser: (data:User):Promise<User> => {
            const url = getApi("user");

            return HttpService.axiosClient.put(url, data);
    },
    changePassword: (data:any) => {
        const url = getApi("change-password")
        return HttpService.axiosClient.put(url, data);
    },
    activeUser: (id:any):Promise<boolean> => {
        const api = getApi("admin/user/active/"+id)
        return HttpService.axiosClient.put(api);
    }


}


export default userService