import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FormContact, LoginPayload, Option, ResponsiveData, User } from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface SupplierState {
    suppliers: FormContact[]
    isLoading: boolean
    total: number,
    currentPage: number
}

// Define the initial state using that type
const initialState: SupplierState = {
    suppliers: [],
    isLoading: false,
    total: 0,
    currentPage: 1
}

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload
    }
    ,
    createSupplier: (state, action:PayloadAction<FormContact>) => {
        state.isLoading = true
    },
    createSupplierSuccess: (state, action:PayloadAction<FormContact>) => {
        state.suppliers = [action.payload, ...state.suppliers];
        state.isLoading = false
    },
    createSupplierFail: (state) => {
        state.isLoading = false
    },
    getSupplier: (state, action:PayloadAction<Option>) => {
        state.isLoading = true
    },
    getSupplierSuccess: (state, action:PayloadAction<ResponsiveData<FormContact>>) => {
        state.isLoading = false
        state.suppliers = action.payload.list
        state.total = action.payload.total
    },
    getSupplierFail: (state) => {
        state.isLoading = false
    },
    editSupplier: (state, action:PayloadAction<FormContact>) => {
        state.isLoading = true
    },
    editSupplierSuccess: (state, action:PayloadAction<FormContact>) => {
        const index = state.suppliers.findIndex(item => item.id === action.payload.id);
        state.suppliers.splice(index, 1, action.payload);
    },
    editSupplierFail: (state) => {
        state.isLoading = false
    },
    deleteSupplier: (state, action:PayloadAction<number>) => {
        state.isLoading = true
    },
    deleteSupplierSuccess: (state, action:PayloadAction<number>) => {
       state.suppliers = state.suppliers.filter(item => item.id !== action.payload)
    },
    deleteSupplierFail: (state) => {
        state.isLoading = false
    }
  
  },
})

export const {createSupplier, createSupplierFail, createSupplierSuccess, getSupplier, getSupplierFail, getSupplierSuccess, deleteSupplier, deleteSupplierFail, deleteSupplierSuccess, editSupplier, editSupplierFail, editSupplierSuccess} = supplierSlice.actions

export default supplierSlice.reducer