import PopUp from "../../components/PopUp";
import { useAppSelector } from "../../hooks/hook";


export default function PopUps () {
    const listPoup = useAppSelector(state => state.popupSlice.lisMessages);

  return (
        <div className="fixed z-[99999] top-[40px] left-[50%] flex flex-col justify-end translate-x-[-50%]">
            {
                listPoup.map((item, index) => {
                    return (
                        <PopUp key={item.id} message={item} />

                    )
                })
            }
        </div>
  )  
} 