import HttpService from "../configs/api";
import { ProducPublicType, ProductItems, productParam, productPublicType, resultProductPublic } from "../typeProps/Productype";
import { ResponsiveData } from "../types";
import getApi from "./getApi";
import { Option } from "../types";
const url = getApi("product");

const ProductPublicService = {
    getProductPublic (data:productPublicType):Promise<resultProductPublic> {
        const searchString = data.search? `search=${data.search}` : "search";
        const url = getApi("product/filter");
        return HttpService.axiosClient.get(`${url}/${data.type}?${searchString}&categoryId=${data.categoryId}&page=${data.page}&limit=${data.limit}&sort=${data.sort}`)
    },
}

export default ProductPublicService;