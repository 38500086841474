
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../configs/redux/store';

import { createBanner, createBannerFail, createBannerSuccess, deleteBanner, deleteBannerFail, deleteBannerSuccess, getBanner, getBannerFail, getBannerSuccess } from '../reducers/banner';
import { hideModal } from '../reducers/modal';
import { pushPopup } from '../reducers/popupSlice';
import { TranslateState } from '../reducers/translate';
import bannerService from '../services/banner';
import { ResponsiveData } from '../types';
import { Banner } from '../types/banner';


function* createBannerMethod({payload}:PayloadAction<Banner[]>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:ResponsiveData<Banner>= yield call(bannerService.create, payload);
        yield put(createBannerSuccess(res.list));
        yield put(pushPopup({
            type: "SUCCESS",
            message: translate.isEnglish ? "Thêm thành công" : "Add successfully"
        }))

       
    }catch(error:any) {
        yield put(createBannerFail());
        yield put(pushPopup({
            type: "ERROR",
            message: error.response.data.title
        }))

    }
}

function* getBannerMethod() {
    try {
        const res:Banner[] = yield call(bannerService.find);
        yield put(getBannerSuccess(res));
     
       
    }catch(error:any) {
        yield put(getBannerFail());
      

    }
}


function* deleteBannerMethod({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:Boolean = yield call(bannerService.delete, payload);
        if(res) {
            yield put(deleteBannerSuccess(payload))
            yield put(pushPopup({
                type: "SUCCESS",
                message: translate.isEnglish ? "Xóa thành công" : "Delete successfully"
            }))
            yield put(hideModal());
        }else {
            yield put(deleteBannerFail())
            yield put(pushPopup({
                type: "ERROR",
                message: translate.isEnglish ? "Xóa thất bại" : "Delete failed"
            }))

        }
    }catch(error) {
        yield put(deleteBannerFail())
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Xóa thất bại" : "Delete failed"
        }))
    }
}



export default function* bannerSaga() {
    yield takeLatest(createBanner.type, createBannerMethod)
    yield takeLatest(getBanner.type, getBannerMethod)
    yield takeLatest(deleteBanner.type, deleteBannerMethod)
}