import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { getSearch, getSearchSuccess } from "../reducers/SearchAllSlice";
import searchAllService from "../services/searchAllService";
import { listSearch, SearchParam } from "../typeProps/searchType";

function* getListSearchSage({ payload }: PayloadAction<SearchParam>) {
  try {
    const res: listSearch = yield call(searchAllService.getListSearch, payload);
    yield put(getSearchSuccess(res));
  } catch (error) {}
}

export default function* searchSagaMid() {
  yield takeLatest(getSearch.type, getListSearchSage);
}
