import { LoginPayload } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { login, loginFail, loginSuccess } from '../reducers/auth';
import authService from '../services/auth';


function* loginSagaMethod({payload}:PayloadAction<LoginPayload>) {
    try {

        const res:{token:string, expired: string} = yield call(authService.login, payload);

        yield put(loginSuccess(res));
    }catch(error) {


        yield put(loginFail("Tài khoản hoặc mật khẩu không đúng"));

    }
}



export default function* authSaga() {
    yield takeLatest(login.type, loginSagaMethod)
}