import { useTranslation } from "react-i18next"
import Button from "../../components/Button";
import Input from "../../components/CareerInput";
import { useFormik } from "formik";
import * as Yup from "yup"
import contactService from "../../services/contact";
import { useAppDispatch } from "../../hooks/hook";
import { pushPopup } from "../../reducers/popupSlice";
export default function FormContact() {
    const [t] = useTranslation();
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
                fullname: '',
                phone: "",
                email: "",
                companyName: "",
                companyAddress: "",
                note: "",
                fromTo: ""
        },
        validationSchema: Yup.object({
                fullname: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.name"), min: 5})),
                phone: Yup.string().required(t("validate.error.required")).matches(/^[0-9]{10}$/, t("validate.error.pattern", {name: t("contact.form.phone")})),
                email: Yup.string().required(t("validate.error.required")).matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t("validate.error.pattern", {name: t("career.form.email")})),
                companyAddress: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.address"), min: 5})),
                companyName: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.name_company"), min: 5})),
                note: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.help"), min: 5})),
        })
        ,
        onSubmit: (values)=> {
                contactService.postContact(values).then(()=> {
                        dispatch(pushPopup({
                                type: "SUCCESS",                         
                                message: t("contact.form.postSuccess")
                        }))

                        resetForm()
                }).catch(()=> {
                        dispatch(pushPopup({
                                type: "ERROR",
                                message: t("contact.form.postFail")
                        }))
                })
        }
})

                const resetForm = () => {
                        formik.resetForm();
                }


    return (
        <div className="mt-[126px] sc991:mt-[24px] mb-[33px]">
                <h2 className="lssm:text-px20 md:text-[32px]  text-text-primary font-bold">{t("contact.title")}</h2>
                <p className="text-text-gray lssm:text-px14 md:text-px16 lssm:my-[15px] sm:my-[26px]">{t("contact.description")}</p>
               
                <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-x-6">
                    <div className="flex flex-col sc>768:col-span-2 mb-6">
                            <label className="text-text-primary font-bold lssm:text-px14  md:text-px16 mb-4">{t("contact.form.name")} <span className="text-[#EB0000]">*</span></label>   
                             <Input value={formik.values.fullname} onChange={formik.handleChange} name="fullname" />
                             <small className="text-px14 text-text-red">{formik.errors.fullname}</small>
                    </div>

                    <div className="flex flex-col sc>768:col-span-2  mb-6">
                            <label className="text-text-primary font-bold lssm:text-px14 md:text-px16 mb-4">{t("contact.form.phone")} <span className="text-[#EB0000]">*</span></label>   
                             <Input value={formik.values.phone} name="phone" onChange={formik.handleChange} />
                             <small className="text-px14 text-text-red">{formik.errors.phone}</small>

                             
                    </div>

                 

                    <div className="flex flex-col mb-6 col-span-2">
                            <label className="text-text-primary font-bold lssm:text-px14 md:text-px16 mb-4">{t("career.form.email")} <span className="text-[#EB0000]">*</span></label>   
                             <Input type="email" value={formik.values.email} name="email" onChange={formik.handleChange}  />
                             <small className="text-px14 text-text-red">{formik.errors.email}</small>

                    </div>

                    <div className="flex flex-col mb-6 col-span-2">
                            <label className="text-text-primary font-bold lssm:text-px14 md:text-px16 mb-4">{t("contact.form.name_company")} <span className="text-[#EB0000]">*</span></label>   
                             <Input value={formik.values.companyName} name="companyName" onChange={formik.handleChange}  />
                             <small className="text-px14 text-text-red">{formik.errors.companyName}</small>

                    </div>

                    <div className="flex flex-col mb-6 col-span-2">
                            <label className="text-text-primary font-bold lssm:text-px14 md:text-px16 mb-4">{t("contact.form.address")} <span className="text-[#EB0000]">*</span></label>   
                             <Input value={formik.values.companyAddress} name="companyAddress" onChange={formik.handleChange}  />
                             <small className="text-px14 text-text-red">{formik.errors.companyAddress}</small>

                    </div>
         
           

                    <div className="flex flex-col mb-6 col-span-2">
                            <label className="text-text-primary font-bold lssm:text-px14 md:text-px16 mb-4">{t("contact.form.help")} <span className="text-[#EB0000]">*</span></label>   
                            <textarea value={formik.values.note} name="note" onChange={formik.handleChange}  className="border-[1px] px-1 py-1 border-solid border-border-color focus:border-text-primary outline-none  rounded-[10px] w-full" rows={8}>

                            </textarea>
                             <small className="text-px14 text-text-red">{formik.errors.note}</small>

                    </div>

                    <div className="flex flex-col mb-6 col-span-2">
                            <label className="text-text-primary font-bold lssm:text-px14 md:text-px16 mb-4">{t("contact.form.know")}</label>   
                             <Input value={formik.values.fromTo} name="fromTo" onChange={formik.handleChange}  />
                    </div>

        

                    <div className="col-span-2 flex md:justify-end items-center">
                        <Button onClick={resetForm} type="button" className="text-text-red sc>768:text-px14 md:w-[100px] border-text-primary sc>768:flex-1" color="empty">{t("contact.form.btn_cancer")}</Button>
                        <Button type="submit" className=" ml-4 sc>768:text-px14 sc>768:flex-1 md:w-[130px]" color="primary">{t("contact.form.btn_submit")}</Button>
                    </div>

                </form>

        </div>
    )

}