import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdAddPhotoAlternate, MdClear } from "react-icons/md";
import DialogConfirm from "../../../components/DialogConfirm";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { showModal } from "../../../reducers/modal";
import { createPartner, deletePartner, getPartner } from "../../../reducers/partner";
import { pushPopup } from "../../../reducers/popupSlice";
import uploadService from "../../../services/uploadImage";
import { UrlResponsive } from "../../../types";

export default function ManagerPartner() {

    const partner = useAppSelector(state => state.partnerSlice);
    const dispatch = useAppDispatch();
    const [t] = useTranslation();

    useEffect(()=> {
        dispatch(getPartner());
    }, [])


    const handleBanner = (event:ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const listFile = event.currentTarget.files
        if(listFile !== null) {
            const files = Array.from(listFile);
            const formData = new FormData();
            files.forEach((file:File, index) => {
                if(file.size < 26675200){
                    formData.append("file", file);
                }else {
                    if(files.length >= 2) {
                        dispatch(pushPopup({
                            message: t("notify.min_images", {no: index  + 1,size: "25MB"}),
                            type: "WARNING"
                        }))
                    } else {
                        dispatch(pushPopup({
                            message: t("notify.min_image", {size: "25MB"}),
                            type: "WARNING"
                        }))
                    }
                }
            })
    
            uploadService.upload(formData).then((urls:UrlResponsive)=> {
                    const bannerUrl = urls.list.map(item => ({imagePath: item.image_path, imageUrl: item.image_url}))
                    dispatch(createPartner(bannerUrl));
            })

        }

    }

    const onInputClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        e.currentTarget.value = ""
    }


    const deleteBannerModal = (id:string)=> {
        dispatch(showModal(<DialogConfirm message='Bạn có muốn xóa hình ảnh đối tác này không?' onClick={()=> handleDelete(id)}  />))
    }

    const handleDelete = (id:string)=> {
        dispatch(deletePartner(+id))
    }


    return <div className="mb-[80px]">
        <p className="text-px16 text-text-primary md:text-px20 font-bold lssm:mb-[18px] md:mb-[40px] mt-[112px]">{t("dashboard.home.customer.title")}</p>
        <div className="grid lssm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
            <label htmlFor='home-partner' className="w-full  border-primary bg-primary text-white-color lssm:h-[90px] md:h-[180px] flex flex-col items-center justify-center rounded-[12px]">
              
            <span className="lssm:text-[32px] md:text-[48px]"><MdAddPhotoAlternate /></span>
                <span className="lssm:mt-[18px] md:mt-[32px] text-px12 lsm-380:text-px14 md:text-px16">{t("dashboard.home.customer.upload")}</span>
                <input onChange={handleBanner} onClick={onInputClick} multiple hidden type="file" id="home-partner" />
            </label>

            {
                partner.partner.map((item)=> {
                    return (
                        <div key={item.id} className="w-full relative border-2 border-solid border-primary lssm:h-[90px] md:h-[180px] flex flex-col items-center justify-center rounded-[5px]">
                        <span className='absolute top-2 right-2 text-text-decoration text-px24 cursor-pointer flex justify-center items-center w-[30px] h-[30px] bg-white-color rounded-[50%]' onClick={()=>deleteBannerModal(item.id+"")}>
                            <MdClear />
                        </span>
                        <img src={item.imageUrl} alt="" className="max-w-full max-h-full" />
                        </div>
                    )
                })
            }

          
    </div>
</div>
}