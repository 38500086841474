import { LoginPayload } from './../types';
import HttpService from "../configs/api";
import getApi from "./getApi";


const authService = {
    login: (data:LoginPayload):Promise<{token:string, expired:string}> => {
            const urlLogin = getApi("authenticate");
            return HttpService.axiosClient.post(urlLogin, data )
    }

}

export default authService;