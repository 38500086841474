import React from "react";
import { useTranslation } from "react-i18next";

type propsTitle = {
  content: string;
  isTranslate?:boolean
};
function TitlePage({ content }: propsTitle) {
  const { t } = useTranslation();
  return (
    <div className="w-full 2xl:py-[74px] py-10 px-[10px] text-center">
      <h3 className="text-center m-0 text-bg_blue_bold 2xl:text-5xl font-bold sm-480:text-2xl text-xl uppercase">
        {t(`${content}`)}
      </h3>
    </div>
  );
}

export default TitlePage;
