import { Device, Option, ResponsiveData } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, debounce, select } from 'redux-saga/effects';
import { createDevice, createDeviceFail, createDeviceSuccess, deleteDevice, deleteDeviceSuccess, 
        getDevice, getDeviceFail, getDeviceSuccess, searchDevice, searchDeviceFail, searchDeviceSuccess,
        updateDevice, updateDeviceSuccess } from '../reducers/device';
import deviceService from '../services/device';
import { hideModal } from '../reducers/modal';
import { pushPopup } from '../reducers/popupSlice';
import { TranslateState } from '../reducers/translate';
import { RootState } from '../configs/redux/store';


function* getDeviceSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<Device> = yield call(deviceService.getDevice, payload)
        yield put(getDeviceSuccess(res))
    }catch(error) {
        yield put(getDeviceFail())
    }
}

function* createDeviceSaga({payload}:PayloadAction<Device>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
       const res:Device = yield call(deviceService.create, payload)
       yield put(createDeviceSuccess(res))
       yield put(hideModal());
       yield put(pushPopup({
        message: translate.isEnglish ? "Thêm thành công" : "Add successfully",
        type: "SUCCESS"
       }))
    }catch(error) {
        yield put(createDeviceFail());
        yield put(hideModal());
        yield put(pushPopup({
         message: translate.isEnglish ? "Thêm thất bại" : "Add failed",
         type: "ERROR"
        }))
    }
}

function* deleteDeviceSaga({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:boolean = yield call(deviceService.delete, payload)
        if(res) {
            yield put(deleteDeviceSuccess(payload));
            yield put(hideModal());
            yield put(pushPopup({
             message: translate.isEnglish ? "Xóa thành công" : "Delete successfully",
             type: "SUCCESS"
            }))
        }else {
           yield put(createDeviceFail());
            yield put(hideModal());
            yield put(pushPopup({
             message: translate.isEnglish ? "Xóa thất bại" : "Delete failed",
             type: "ERROR"
            }))
        }
     }catch(error) {
         yield put(createDeviceFail());
         yield put(hideModal());
            yield put(pushPopup({
            message: translate.isEnglish ? "Xóa thất bại" : "Delete failed",
             type: "ERROR"
            }))
     }

}

function* updateDeviceSaga({payload}:PayloadAction<Device>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

        try {
            const res:Device = yield call(deviceService.update, payload);
            yield put(updateDeviceSuccess(res));
            yield put(hideModal());
            yield put(pushPopup({
                message: translate.isEnglish ? "Cập nhật thành công" : "Update successfully",
             type: "SUCCESS"
            }))

        }catch(error) {
            yield put(hideModal());
            yield put(pushPopup({
                message: translate.isEnglish ? "Cập nhật thất bại" : "Update failed",
             type: "ERROR"
            }))
        }
}


function* searchDeviceSaga({payload}:PayloadAction<{keyword:string, option:Option}>) {
    try {   
        if(payload.keyword.trim() !== "") {
            const res:ResponsiveData<Device> = yield call(deviceService.search, payload);
            yield put(searchDeviceSuccess(res));

        }else {
            yield put(getDevice(payload.option))
        }
    }catch(error) {
        yield put(searchDeviceFail());
    }
}

export default function* deviceSaga() {
    yield takeLatest(getDevice.type, getDeviceSaga)
    yield takeLatest(createDevice.type, createDeviceSaga)
    yield takeLatest(deleteDevice.type, deleteDeviceSaga)
    yield takeLatest(updateDevice.type, updateDeviceSaga)
    yield debounce(1000,searchDevice.type, searchDeviceSaga)
}