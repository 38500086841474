
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddPhotoAlternate, MdClear } from 'react-icons/md';
import resizeFile from '../../../common/reSize';
import DialogConfirm from '../../../components/DialogConfirm';
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { createBanner, deleteBanner, getBanner } from '../../../reducers/banner';
import { showModal } from '../../../reducers/modal';
import { pushPopup } from '../../../reducers/popupSlice';
import uploadService from '../../../services/uploadImage';
import { UrlResponsive } from '../../../types';


export default function ManagerBanner() {
    const dispatch = useAppDispatch();
    const [t] = useTranslation();

    useEffect(()=> {
        dispatch(getBanner());
    }, [])



    const banner = useAppSelector(state => state.bannerSlice);
    const handleBanner =  async (event:ChangeEvent<HTMLInputElement>) => {
        let listFile = event.currentTarget.files;

        if(banner.banners.length + listFile!.length > 7) {
            dispatch(pushPopup({
                message: t("notify.banner_max", {max: 7}),
                type: "WARNING"
            }))
            return;
        }
        

        
        if(listFile !== null) {
            const files = Array.from(listFile);
            
            const formData = new FormData();
            const FileArr =   files.map( async (file:File, index) => {
                if(file.size < 26675200){
                    if(file.size >= 1048576) {
                        const newFile = await resizeFile(file, {width: 1920, height: 860})
                        formData.append("file", newFile);

                    }else {
                        formData.append("file", file);
                    }
                }else {
                    if(files.length >= 2) {
                        dispatch(pushPopup({
                            message: t("notify.min_images", {no: index  + 1,size: "25MB"}),
                            type: "WARNING"
                        }))
                    } else {
                        dispatch(pushPopup({
                            message: t("notify.min_image", {size: "25MB"}),
                            type: "WARNING"
                        }))
                    }
                }
            })

            await Promise.all(FileArr);
    
            uploadService.upload(formData).then((urls:UrlResponsive)=> {
                    const bannerUrl = urls.list.map(item => ({imagePath: item.image_path, imageUrl: item.image_url}))
                    dispatch(createBanner(bannerUrl));
            })
            
            event.currentTarget.value = ""
        }


    }

    const onInputClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        e.currentTarget.value = ""
    }

    const deleteBannerModal = (id:string)=> {
        dispatch(showModal(<DialogConfirm message='Bạn có muốn xóa Banner này không?' onClick={()=> handleDelete(id)}  />))
    }

    const handleDelete = (id:string)=> {
        dispatch(deleteBanner(+id))
    }



    return <div>
        <p className="text-px16 md:text-px20 text-text-primary font-bold ">{t("dashboard.home.banner.title")}</p>
        <p className='text-px14 md:text-px16 text-text-red mt-2 sm-390:mb-[12px] md:mb-[25px]'>{t('validate.error.image')}</p>
        <div className="grid lssm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
            {
                banner.banners.length < 8 ? (
                <label htmlFor='home-banner' className="w-full border-2 border-dashed border-primary text-text-primary lssm:h-[90px] md:h-[180px] flex flex-col items-center justify-center rounded-[5px]">
                     <span className="text-primary lssm:text-[32px] md:text-[48px]"><MdAddPhotoAlternate /></span>
                    <span className="lssm:mt-[18px] md:mt-[32px] lssm:text-px12 lsm-380:text-px14 md:text-px16">{t("dashboard.home.banner.upload")}</span>
                    <input hidden multiple onChange={handleBanner} onClick={onInputClick} type="file" id="home-banner" />
                </label>

                ) : <></>
            }

                {
                        banner.banners.map((item, index)=> {
                            return (
                                <div key={item.id} className="w-full relative border-2 border-solid border-primary lssm:h-[90px] md:h-[180px] flex flex-col items-center justify-center rounded-[5px] overflow-hidden">
                                   <img src={item.imageUrl} alt="" className='w-full h-full object-cover' />
                                    <span className='absolute top-2 right-2 text-text-decoration text-px24 cursor-pointer flex justify-center items-center w-[30px] h-[30px] bg-white-color rounded-[50%]' onClick={()=>deleteBannerModal(item.id+"")}>
                                        <MdClear />
                                    </span>
                                    {
                                        index === 0 && (
                                        <span className="absolute bottom-5 left-5">
                                        </span>

                                        )
                                        
                                    }
                                </div>
                            )
                        })
                }

    
        </div>
    </div>
}