import HistoryMobileItem from './HistoryMobileItem';
type Props = {
    historyData: any[]
}

export default function HistoryMobile({historyData}:Props) {


    return  <div className="relative h-auto lssm:my-[24px] md:my-[40px] xl:my-[80px]">
            {
                historyData.map((his:any, index:any)=> {
                        return <HistoryMobileItem index={index} key={index} his={his} />
                })
            }
            <div className="h-full w-[2px] bg-primary absolute top-[40px] left-[22px] ">

            </div>
    </div>
}