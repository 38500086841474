
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../configs/redux/store';

import { hideModal } from '../reducers/modal';
import { createPartner, createPartnerFail, createPartnerSuccess, deletePartner, deletePartnerFail, deletePartnerSuccess, getPartner, getPartnerFail, getPartnerSuccess } from '../reducers/partner';
import { pushPopup } from '../reducers/popupSlice';
import { TranslateState } from '../reducers/translate';
import partnerService from '../services/partner';
import { ResponsiveData } from '../types';
import { Banner } from '../types/banner';


function* createBannerMethod({payload}:PayloadAction<Banner[]>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    try {
        const res:ResponsiveData<Banner>= yield call(partnerService.create, payload);
        yield put(createPartnerSuccess(res.list));
        yield put(pushPopup({
            type: "SUCCESS",
            message: translate.isEnglish ? "Thêm thành công." : "Add successfully."
        }))

       
    }catch(error:any) {
        yield put(createPartnerFail());
        yield put(pushPopup({
            type: "ERROR",
            message: error.response.data.title
        }))

    }
}

function* getBannerMethod() {
    try {
        const res:Banner[] = yield call(partnerService.find);
        yield put(getPartnerSuccess(res));
     
       
    }catch(error:any) {
        yield put(getPartnerFail());
      

    }
}


function* deleteBannerMethod({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  
    try {
        const res:Boolean = yield call(partnerService.delete, payload);
        if(res) {
            yield put(deletePartnerSuccess(payload))
            yield put(pushPopup({
                type: "SUCCESS",
                message: translate.isEnglish ? "Xóa thành công." : "Delete successfully."
            }))
            yield put(hideModal());
        }else {
            yield put(deletePartnerFail())
            yield put(pushPopup({
                type: "ERROR",
                message: translate.isEnglish ? "Xóa thất bại." : "Delete failed."
            }))

        }
    }catch(error) {
        yield put(deletePartnerFail())
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Xóa thất bại." : "Delete failed."
        }))
    }
}



export default function* partnerSaga() {
    yield takeLatest(createPartner.type, createBannerMethod)
    yield takeLatest(getPartner.type, getBannerMethod)
    yield takeLatest(deletePartner.type, deleteBannerMethod)
}