import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Modal from "./containers/Modal";
import PopUps from "./containers/Popups";
import { useAppDispatch, useAppSelector } from "./hooks/hook";
import { getCurrentUser } from "./reducers/user";
import RootRouter from "./Router/RootRouter";


export default function App() {

  const auth = useAppSelector(state => state.authSlice)

  // const currentUser = useAppSelector(state => state.userSlice.currentUser);
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(()=> {
      dispatch(getCurrentUser()); 
  }, [])



  return (
    <BrowserRouter>
      <Modal />
      <PopUps />
      <RootRouter />
    </BrowserRouter>
  );
}
