import React, { ChangeEvent, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useAppSelector } from "../../hooks/hook";

type propInput = {
  ChangeInputFc: (param: string) => void;
};
function InputSearch({ ChangeInputFc }: propInput) {
  const [value, setValue] = useState<string>("");
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
    ChangeInputFc(value);
  };
  return (
    <div className="w-full rounded-md flex items-center border  h-[50px] xl:h-[60px] focus-within:border-bg_blue_bold">
      <div className="w-[60px] h-full flex items-center justify-center border-r border-r-border-gray">
        <AiOutlineSearch className="text-bg_blue_bold text-3xl" />
      </div>
      <input
        className="rounded-md w-11/12 sm-480:text-base text-sm font-normal text-black px-6 h-full focus:outline-none"
        type="text"
        value={value}
        onChange={(event) => {
          handleChangeInput(event);
        }}
        name="inputSearch"
        placeholder={isEnglish ? "Nhập tìm kiếm ..." : "Search"}
      />
    </div>
  );
}

export default InputSearch;
