import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks/hook";
import { hideModal } from "../../reducers/modal";

export default function Modal() {
  const ModalData = useAppSelector((state) => state.ModalSlice);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ModalData.isShow) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [ModalData.isShow]);
  return ModalData.isShow ? (
    <div className="fixed z-30 inset-0 w-full h-[100vh] scrollbar overflow-y-scroll flex  justify-center">
      <div className="modal z-30" onClick={() => dispatch(hideModal())}></div>
      <div className="relative z-50 mt-auto mb-auto">{ModalData.Element}</div>
    </div>
  ) : (
    <></>
  );
}
