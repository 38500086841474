import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listSearch, SearchParam } from "../typeProps/searchType";
import { Option } from "../types";

interface initialStateReferent {
  total: number;
  loadding: boolean;
  error: string | null;
  listSearch: [];
  currentPage: number;
}

const initialState: initialStateReferent = {
  total: 0,
  loadding: false,
  error: null,
  listSearch: [],
  currentPage: 1,
};

export const searchAllSlice = createSlice({
  name: "searchAll",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    getSearch(state, action: PayloadAction<SearchParam>) {
      state.loadding = true;
    },
    getSearchSuccess(state, action: PayloadAction<listSearch>) {
      const resultList = action.payload;
      state.listSearch = resultList.list;
      state.total = resultList.total;
      state.loadding = false;
    },
    setStateSearch(state){
      state.currentPage=1;
      state.listSearch = [];
      state.total=0;
    },
    getSearchFall(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setSearchClient :(state)=> {
      state.total=0;
      state.listSearch =[]
    }
  },
});

export const { getSearch, getSearchSuccess, getSearchFall, setCurrentPage,setStateSearch,setSearchClient} =
  searchAllSlice.actions;

export default searchAllSlice.reducer;
