

export default function convertToHtml(jsonStr:string) {
    let obj = JSON.parse(jsonStr);
 
    let html = '';
     obj["blocks"].forEach(function(block:any, index:number) {
         switch (block['type']) {
             case 'paragraph':
                 html += `<p class='text-${block['tunes']['alignTool']["alignment"]}'>`+ block['data']['text'] +'</p>';
                 break;
             
             case 'header':
                 html += `<h class='text-${block['tunes']['alignTool']["alignment"]}'`+ block['data']['level'] +'>'+ block['data']['text'] +'</h'+ block['data']['level'] +'></br>';
                 break;
 
             case 'raw':
                 html += block['data']['html'];
                 break;
 
             case 'list':
                let lsType = (block['data']['style'] == 'ordered') ? 'ol' : 'ul';
                 html += '<' + lsType + '>';
                 block['data']['items'].forEach(function(item:any, index:any) {
                     html += '<li>' + item + '</li>';
                 });
                 html += '</' + lsType + '></br>';
                 break;
             
             case 'code':
                 html += '<pre><code class="language-'+ block['data']['lang'] +'">'+ block['data']['code'] +'</code></pre></br>';
                 break;

               case 'table':
                 // html += '<pre><code class="language-'+ block['data']['lang'] +'">'+ block['data']['code'] +'</code></pre>';
                 let thead = block.withHeadings ? "thead" : "tbody";
                 let contentString:string = "";
                 block["data"]["content"].forEach((item:string[], index:any) => {
                     contentString += `<tr>
                       ${
                         item.reduce((cu, item )=> {
                           return cu += ("<td>" + item + "</td>")
                         }, "")
                       }
                     </tr>`
                 })
                
                 let table:string = "<table class='border border-solid border-border-color w-full'>" + contentString + "</table></br>";
                 html+= table
                 break;
             
             case 'image':
                 html += '<div class="w-full flex justify-center"><img className="w-auto h-auto max-w-full object-contain" src="'+ block['data']['file']['url'] +'" /></div></br>';
                 break;
             
             default:
                 break;
         }
     });
     
     return html;
 }
   