import HttpService from "../configs/api";
import { Album, ResponsiveData, TopicImage } from "../types";
import getApi from "./getApi";


const albumService = {
    postAlbum: (data:Album):Promise<Album> => {
        const url = getApi('album');
        return HttpService.axiosClient.post(url, data);
    },
    getAlbum: ():Promise<Album> => {
        const url = getApi("album");
        return HttpService.axiosClient.get(url)
    },
    getById: (id:number):Promise<Album> => {
        const url = getApi(`album/${id}`);

        return HttpService.axiosClient.get(url);
    },
    getImagesById: (id:number):Promise<TopicImage[]> => {
        const url = getApi(`album/image/${id}`);

        return HttpService.axiosClient.get(url);
    },
    postImageAlbum: (id:number, data:TopicImage[]):Promise<ResponsiveData<TopicImage>> => {
        const url = getApi("album/image/"+id);
        return HttpService.axiosClient.post(url, data)
    },
    deleteImageAlbum: (ids:number[]):Promise<boolean> => {
        const url = getApi("album/image");
        return HttpService.axiosClient.delete(url, {data: ids})
    },
    deleteAlbum: (id:number):Promise<boolean> => {
            const url = getApi("album/"+id);
        return HttpService.axiosClient.delete(url);
    },
    updateAlbum: (data:Album):Promise<Album> => {
        const url = getApi("album");

        return HttpService.axiosClient.put(url, data);
    }
}

export default albumService;