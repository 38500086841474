type Props = {
    url: string,
    size: number
}

export default function Avatar ({url, size}:Props) {

    return (
        <img src={url} alt="avatar" style={{width: size, height: size}} className="rounded-[100rem] object-cover" />
    )

}