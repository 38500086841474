import { ChangeEvent } from "react"

type Props = {
    type?: "text"| "email" | "password" | "date"
    onChange?: (event:ChangeEvent<HTMLInputElement>)=> void
    onBlur?:(event:ChangeEvent<HTMLInputElement>)=> void
    value?: string
    name?: string
    id?: string
    readOnly?: boolean
    list?: string
    className?: string
}

export default function Input ({type, onChange, value, name, id, readOnly, list,className, onBlur}:Props) {


    return (    
            <input type={type ? type : "text"} id={id} name={name} 
            placeholder=" " readOnly={readOnly ? readOnly : false} list={list} onChange={onChange} 
            onBlur={onBlur}
            className={`border-[1px] px-1 border-solid border-border-color outline-none focus:border-text-primary  rounded-[10px] w-full h-[50px] ${className}`} value={value} />        
    )
}