import HttpService from "../configs/api";
import { categoryPost, categoryType } from "../typeProps/Productype";
import getApi from "./getApi";
const url = getApi("categories");
const urlCate = getApi("category");
const CategoryService = {
  getAll: (): Promise<categoryType[]> => {
    return HttpService.axiosClient.get(url);
  },
  addCategory: (data: categoryPost): Promise<categoryType> => {
    return HttpService.axiosClient.post(urlCate, data);
  },
  deleteCategory: (id: number): Promise<boolean> => {
    return HttpService.axiosClient.delete(`${urlCate}/${id}`);
  },
  editCategory: (data:categoryType):Promise<categoryType>=> {
    return HttpService.axiosClient.put(urlCate,data)
  }
};
export default CategoryService;
