import NewsDetail from "../containers/News/NewsDetail";
import RelatedNews from "../containers/News/RelatedNews";



export default function DetailNews() {

    return (
        <div className="w-1920:px-[162px] flex sc991:flex-col sc>768:px-[24px] md:px-[100px] xl:px-[80px]" >
            <div className="sc<992:flex-[2]">
           <NewsDetail />
            </div>
            <div  className="sc<992:flex-1">
                <RelatedNews />
            </div>
        </div>

    )


}

