import { Contact, FormContact, Option, ResponsiveData } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { deleteContactFail, deleteContactReduce, deleteContactSuccess, getContact, getContactFail, getContactSuccess, searchContact, searchContactFail, searchContactSuccess, setCurrentPage } from '../reducers/contact';
import contactService from '../services/contact';
import { hideModal } from '../reducers/modal';
import { pushPopup } from '../reducers/popupSlice';
import { createSupplier, createSupplierFail, createSupplierSuccess, deleteSupplier, deleteSupplierFail, deleteSupplierSuccess, editSupplier, editSupplierFail, editSupplierSuccess, getSupplier, getSupplierFail, getSupplierSuccess } from '../reducers/supplier';
import { RootState } from '../configs/redux/store';
import { TranslateState } from '../reducers/translate';


function* getContactSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<Contact> = yield call(contactService.getContacts,payload);
        yield put(getContactSuccess(res));
    }catch(error) {
        yield put(getContactFail());
    }   


}

function* deleteContactSaga ({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

        try {
            const res:boolean = yield call(contactService.deleteContact, payload);
            if(res) {
                const {contactList, currentPage} = yield select((state:RootState) => state.contactSlice)
                if(contactList.length === 1 && currentPage > 1) {
                   yield put(setCurrentPage(currentPage - 1))
                }else {
                    yield put(getContact({
                        page: currentPage,
                        limit: 10
                    }))
                }
                yield put(deleteContactSuccess(payload))
                yield put(hideModal());
                yield put(pushPopup({
                    type: "SUCCESS",
                    message: translate.isEnglish ? "Xóa thành công" : "Delete successfully"
                }));
            }
        }catch(error) {
            yield put(deleteContactFail());
            yield put(hideModal());
            yield put(pushPopup({
                type: "ERROR",
                message: translate.isEnglish ? "Xóa thất bại" : "Delete failed"
            }));
        }
}

function* createSupplierSaga({payload}:PayloadAction<FormContact>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:FormContact = yield call(contactService.createFormContact, payload)
        yield put(createSupplierSuccess(res));
        yield put(pushPopup({
            type: "SUCCESS",
            message: translate.isEnglish ? "Thêm thành công" : "Add successfully"
        }))
        yield put(hideModal())
    }catch(error) {
        yield put(createSupplierFail());
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Thêm thất bại" : "Add failed"
        }))
        yield put(hideModal())
    }
}


function* getSupplierSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<FormContact> = yield call(contactService.getFormContact, payload)
        yield put(getSupplierSuccess(res))
    }catch(error) {
        yield put(getSupplierFail());

    }
}

function* editSupplierSaga({payload}:PayloadAction<FormContact>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

        try {
            const res:FormContact = yield call(contactService.editFormContact, payload);
            yield put(editSupplierSuccess(res));
            yield put(hideModal())
            yield put(pushPopup({
                message: translate.isEnglish ? "Cập nhật thành công" : "Update successfully",
                type: "SUCCESS"
            }))
        }catch(error) {
            yield put(editSupplierFail());
            yield put(pushPopup({
                message: translate.isEnglish ? "Cập nhật thất bại" : "Update failed",
                type: "ERROR"
            }))
        }
}

function* deleteSupplierSaga({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:boolean = yield call(contactService.deleteFormContact, payload )
        if(res) {
            yield put(deleteSupplierSuccess(payload))
            yield put(hideModal())
            yield put(pushPopup({
                type: "SUCCESS",
                message: translate.isEnglish ? "Xóa thành công" : "Delete successfully"
            }))
        }else {
            yield put(deleteSupplierFail())
            yield put(hideModal())
            yield put(pushPopup({
                type: "ERROR",
                message: translate.isEnglish ? "Xóa thất bại" : "Delete failed"
            }))
        }
    }catch(error) {
        yield put(deleteSupplierFail())
        yield put(hideModal())
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Xóa thất bại" : "Delete failed"
        }))
    }
}


function* searchContactSaga({payload}:PayloadAction<{keyword:string, option:Option}>) {
    try {
        if(payload.keyword.trim() !== "") {
            const res:ResponsiveData<Contact> = yield call(contactService.search, payload);
            yield put(searchContactSuccess(res));
        }else {
            yield put(getContact(payload.option))
        }
    }catch(error) {
        yield put(searchContactFail());

    }
}


export default function* contactSaga() {
    yield takeLatest(getContact.type, getContactSaga)
    yield takeLatest(deleteContactReduce.type, deleteContactSaga)
    yield takeLatest(createSupplier.type, createSupplierSaga)
    yield takeLatest(getSupplier.type, getSupplierSaga)
    yield takeLatest(editSupplier.type, editSupplierSaga)
    yield takeLatest(deleteSupplier.type, deleteSupplierSaga)
    yield debounce(1000, searchContact.type, searchContactSaga)
}