import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import convertToHtml from "../../common/convertoHtml";
import { useAppSelector } from "../../hooks/hook";
import newService from "../../services/newService";
import { News } from "../../types";
import Button from "../Button";

export default function ViewsNew() {
    const [news, setNews] = useState<News>({
        titleEn: "",
        titleVi: "",
        descriptionEn: "",
        descriptionVi: "",
        contentEn: "",
        contentVi: "",
        avatarPath: "",
        avatarUrl: ""
        
    });

    const params = useParams();
    const navigate = useNavigate()
    const [t] = useTranslation();

    useEffect(() => {
        newService.getById(parseInt(params.id+"")).then(data => {
            setNews(data);
        })
    }, [])

    const goBack = () => {
      navigate(-1)
    }

    return (
        <div className="w-full h-auto mt-[50px] rounded-md mb-[62px]">
        <p className="text-[32px] mb-[50px] text-center uppercase">
          
        {t("dashboard.news.detail.title")}
        </p>
  
        <div className="w-full mb-[30px]">
          <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
          {t("dashboard.news.create.form.title")}
          </p>
          <input
            name="titleVi"
            readOnly={true}

            value={news?.titleVi}
            type="text"
            className="rounded-md border focus:outline-none w-full p-3 text-base"
          />
        </div>
        <div className="w-full mb-[30px]">
          <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold"> {t("dashboard.news.create.form.description")}</p>
          <input
            name="descriptionVi"
            readOnly={true}
            value={news?.descriptionVi}
            type="text"
            className="rounded-md border focus:outline-none w-full p-3 text-base"
          />
        </div>
  
        <div className="w-full mb-[30px]">
          <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
          {t("dashboard.news.create.form.content")}
          </p>
          {
            news?.contentVi !== "" ? (
                <div dangerouslySetInnerHTML={{
                  __html: convertToHtml(news?.contentVi+"")
                }}>

          </div>
            ) : <></>
          }

        </div>
  
        <div className="w-full mb-[70px]">
          <p className="mb-[10px] text-primary lssm:text-px14 md:text-px20 font-bold">
          {t("dashboard.news.detail.image_avatar")}
          </p>
  
          <div className="py-5 px-5 rounded-md border border-border-gray flex items-center">
            <div className="flex">
              <div className="w-[250px] h-[141px] flex items-center justify-center bg-bg-gray rounded-md ">
  
                  <img
                    className="w-full h-full rounded-md"
                    src={news?.avatarUrl}
                    alt="imagedefault"
                  />
              </div>
            </div>
          </div>
        </div>
  
        <div className="flex justify-end">
          <button
            onClick={goBack}
            className="mr-[10px] h-[50px] sc>768:flex-1 md:w-[85px]  border rounded-md border-text-lightred text-text-lightred font-medium text-px16 text-center"
          >
            {t("button.cancel")}
          </button>
       <Button onClick={()=> navigate(`/quanly/tintuc/edit/${params.id}`)} color="primary" className="sc>768:flex-1 md:w-auto h-[50px]">{t("dashboard.news.detail.btn_edit")}</Button>
        </div>
      </div>
    )

}