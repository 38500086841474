import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../configs/redux/store";
import { hideModal } from "../reducers/modal";
import { pushPopup } from "../reducers/popupSlice";
import {
  editProduct,
  editProductSucces,
  ProductFail,
  getProducts,
  getProductSuccess,
  putProductSuccess,
  putProducts,
  searchProductSuccess,
  searchProducts,
} from "../reducers/products";
import { TranslateState } from "../reducers/translate";
import producService from "../services/products";
import { ProducPublicType, ProductItems, productParam, productTypeState } from "../typeProps/Productype";
import { Option } from "../types";

function* getProductsList({ payload }: PayloadAction<Option>) {
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
  const {isEnglish} = translate;
  try {
    const res: { total: number; list: ProducPublicType[] } = yield call(
      producService.getProductManager,
      payload
    );
    yield put(getProductSuccess(res));
  } catch (error) {
    yield put(ProductFail(isEnglish ? "Có lỗi không hiển thị được danh sách" : "There is an error that the list cannot be displayed"));
  }
}

function* editProductSaga({ payload }: PayloadAction<ProducPublicType>) {
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
  const {isEnglish} = translate;
  try {
    const res: ProducPublicType = yield call(
      producService.editProductService,
      payload
    );
    yield put(editProductSucces(res));
    yield put(
      pushPopup({
        type: "SUCCESS",
        message: isEnglish ? "Sửa sản phẩm thành công" : "Successful product repair",
      })
    );
    yield put(hideModal());
  } catch (error) {
    yield put(
      pushPopup({
        type: "ERROR",
        message: isEnglish ? "Có lỗi thử lại sau!" : "Error try again later!",
      })
    );
    yield put(hideModal())
  }
}
function* putProductSaga({ payload }: PayloadAction<number>) {
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
  const {isEnglish} = translate;
  try {
    const res : ProductItems = yield call(producService.putProducStatus, payload);
    yield put(putProductSuccess(payload));
    yield put(
      pushPopup({
        type: "SUCCESS",
        message: isEnglish ? "Thay đổi trạng thái sản phẩm thành công" : "Product status change successful",
      })
    );
  } catch (error) {
  
    yield put(
      pushPopup({
        type: "WARNING",
        message: isEnglish ? "Tối đa 6 sản phẩm tiêu biểu" : "Up to 6 featured products",
      })
    );
  }
}

function* searchProductsSaga({payload}: PayloadAction<productParam>){
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
  const {isEnglish} = translate;
  try {
    const res: productTypeState = yield call(producService.searchProductsManager, payload);
    yield put(searchProductSuccess(res));
    
  } catch (error) {
    yield put(ProductFail(isEnglish ? "Không tìm thấy sản phẩm" : "No products found"));
  }
}

export default function* ProductSagaMid() {
  yield takeLatest(getProducts.type, getProductsList);
  yield takeLatest(editProduct.type, editProductSaga);
  yield takeLatest(putProducts.type, putProductSaga);
  yield takeLatest(searchProducts.type, searchProductsSaga);
}
