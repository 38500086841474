import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../configs/redux/store";
import { pushPopup } from "../reducers/popupSlice";
import {
  getProdutDetail,
  getProdutDetailFail,
  getProdutDetailSucces,
} from "../reducers/ProductDetails";
import { TranslateState } from "../reducers/translate";
import producService from "../services/products";
import { ProductItems } from "../typeProps/Productype";

function* getProducDetailSaga({ payload }: PayloadAction<number>) {
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
  try {
    const res: ProductItems = yield call(
      producService.getProducDetail,
      payload
    );
    yield put(getProdutDetailSucces(res));
  } catch (error) {
    yield put(
      getProdutDetailFail(isEnglish ? "Có lỗi không hiển thị được chi tiết sản phẩm!" : "There is an error that the product details cannot be displayed!")
    );
    yield put(
      pushPopup({
        type: "WARNING",
        message: isEnglish ? "Có lỗi không hiển thị được vào lúc này!" : "There is an error that cannot be displayed at this time!",
      })
    );
  }
}

export default function* ProductDetailSagaMid() {
  yield takeLatest(getProdutDetail.type, getProducDetailSaga);
}
