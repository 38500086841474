
import Resizer from "react-image-file-resizer";
 const resizeFile = (file:File, option?:{width:number, height:number}):Promise<File> => new Promise(resolve => {

    Resizer.imageFileResizer(file, option?.width ? option?.width : 300, option?.height ? option?.height : 300 ,'JPEG', 100, 0,
    (uri:any) => {
      resolve(uri);
    }, 'file' );
});

export default resizeFile;