import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoginPayload, User } from "../types";
import UserService from "../services/UserService";
// import {ModalState} from "../types"

// Define a type for the slice state
interface AuthState {
  isLogin: boolean;
  isLoading: boolean;
  error: string;
}

// Define the initial state using that type
const initialState: AuthState = {
  isLogin: false,
  isLoading: false,
  error: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginPayload>) => {
      state.isLoading = true;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{ token: string; expired: string }>
    ) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("expired", action.payload.expired);
      state.isLoading = false;
      state.isLogin = true;
    },
    loginFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isLogin = false;

      state.error = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("expired");
      localStorage.removeItem("account");
      if(UserService.isLoggedIn()) {
        UserService.doLogout();
      }
      state.isLogin = false;
    },
    loginHasToken: (state) => {
      state.isLogin = true;
    },
  },
});

export const { login, loginSuccess, loginFail, logout, loginHasToken } =
  authSlice.actions;

export default authSlice.reducer;
