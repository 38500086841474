import { Option, Project, ResponsiveData } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import {getProjectHome, getProjectHomeSuccess, getProjects, getProjectsActive, getProjectsActiveSuccess, getProjectsFail, getProjectsSuccess } from '../reducers/project';
import projectServiece from '../services/project';


function* getProjectSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<Project> = yield call(projectServiece.get, payload); 
        yield put(getProjectsSuccess(res));
    }catch(error) {
        yield put(getProjectsFail());

    }
}

function* getProjectActiveSaga(){
    try{
        const res:Project[] = yield call(projectServiece.getProjectActive); 
        yield put(getProjectsActiveSuccess(res));
    }catch(error) {
    }
}

function* getProjectHomeSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<Project> = yield call(projectServiece.getProjectHome, payload);
        yield put(getProjectHomeSuccess(res)); 
    }catch(error){

    }

}


export default function* projectSaga() {
    yield takeLatest(getProjects.type, getProjectSaga)
    yield takeLatest(getProjectsActive.type, getProjectActiveSaga)
    yield takeLatest(getProjectHome.type, getProjectHomeSaga)
}