import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  categoryPost,
  categoryType
} from "../typeProps/Productype";

interface productReferent {
  total: number;
  loadding: boolean;
  error: string | null;
  categoryList: categoryType[];
  categoryFilter: number[]
}

const initialState: productReferent = {
  total: 0,
  loadding: false,
  error: null,
  categoryList: [],
  categoryFilter: []
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    getCategory(state) {
      state.loadding = true;
    },
    getCategoryFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    getCategorySuccess(state, action: PayloadAction<{total: number, list: categoryType[]}>) {
      state.categoryList = action.payload.list;
      state.total = action.payload.total;
      state.loadding = false;
    },
    addCategory(state, action: PayloadAction<categoryPost>) {
      state.loadding = true;
    },
    addCategorySuccess(state, action: PayloadAction<categoryType>) {
      state.categoryList = [...state.categoryList, action.payload];
      state.loadding = false;
    },
    deleteCategory(state, action: PayloadAction<number>) {
    },
    deleteCategorySucces(state, action: PayloadAction<number>) {
      const newCategory = state.categoryList.filter((item) => {
        return item.id !== action.payload;
      });
      state.categoryList = newCategory;
    },
    editCategory(state,action:PayloadAction<categoryType>){
    },
    editCategorySuccess(state,action:PayloadAction<categoryType>){
      const newItem = action.payload;
      const newState = state.categoryList.map((items)=> {
        if(items.id === newItem.id){
          items=newItem
        }
        return items;
      });
      state.categoryList = newState;
    },
    changeCategoryChecked(state, action:PayloadAction<number>){
      const checkId = state.categoryFilter.find((item, index) => {
        return item === action.payload;
      })

      if(checkId){
        state.categoryFilter = []
      }else{
        state.categoryFilter = [action.payload]
      }
    },
    deleteCategoryChecked(state){
      state.categoryFilter = []
    }
  },
});

export const {
  getCategory,
  getCategorySuccess,
  getCategoryFail,
  addCategory,
  addCategorySuccess,
  deleteCategory,
  deleteCategorySucces,
  editCategory,
  editCategorySuccess,
  changeCategoryChecked,
  deleteCategoryChecked
} = categorySlice.actions;

export default categorySlice.reducer;
