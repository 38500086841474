
import { LoginPayload, Option, ResponsiveData, HistoryState } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest, throttle } from 'redux-saga/effects';
import { login, loginFail, loginSuccess } from '../reducers/auth';
import authService from '../services/auth';
import { createHistory, createHistoryFail, createHistorySuccess, deleteHistory, deleteHistoryFail, deleteHistorySuccess, getHistory, getHistoryFail, getHistoryPublic, getHistoryPublicFail, getHistoryPublicSuccess, getHistorySuccess, putHistory, putHistoryFail, putHistorySuccess } from '../reducers/history';
import historyService from '../services/history';
import { pushPopup } from '../reducers/popupSlice';
import { hideModal } from '../reducers/modal';
import { TranslateState } from '../reducers/translate';
import { RootState } from '../configs/redux/store';


function* getHistorySaga({payload}:PayloadAction<Option>) {
    try {

        const res:ResponsiveData<HistoryState> = yield call(historyService.getHistory, payload);
        yield put(getHistorySuccess(res));
    }catch(error) {
       yield put(getHistoryFail())
       

    }
}

function* getHistoryPublicSaga() {
    try {

        const res:ResponsiveData<HistoryState> = yield call(historyService.getHistoryPublic);
        yield put(getHistoryPublicSuccess(res));
    }catch(error) {
       yield put(getHistoryPublicFail())
       

    }
}

function* createHistorySaga({payload}:PayloadAction<HistoryState>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  
    try {
        const res:HistoryState = yield call(historyService.createHistory, payload);
        yield put(createHistorySuccess(res));
        yield put(pushPopup({
            type: "SUCCESS",
            message: translate.isEnglish ? "Thêm thành công" : "Add successfully."
        }))
    }catch(error) {
        yield put(createHistoryFail());
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Thêm thất bại" : "Add failed"
        }))
    }
}

function* updateHistorySaga({payload}:PayloadAction<HistoryState>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:HistoryState = yield call(historyService.put, payload)
        yield put(putHistorySuccess(res));
        yield put(pushPopup({
            message: translate.isEnglish ? "Cập nhật thàng công" : "Update successfully",
            type: "SUCCESS"
        }))

    }catch(error) {
        yield put(putHistoryFail());
        yield put(pushPopup({
            message: translate.isEnglish ? "Cập nhật thất bại" : "Update failed",
            type: "ERROR"
        }))
    }
 
}

function* deleteHistorySaga({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try{
        const res:boolean = yield call(historyService.delete, payload);
        if(res) {
            yield put(deleteHistorySuccess(payload));
            yield put(pushPopup({
                message: translate.isEnglish ? "Xóa thành công" : "Delete successfully",
                type: "SUCCESS"
            }))
        }else {
            yield put(deleteHistoryFail());
            yield put(pushPopup({
                message: translate.isEnglish ? "Xóa thất bại" : "Delete failed",
                type: "ERROR"
            }))
        }
        yield put(hideModal());
    }catch(error) {
        yield put(deleteHistoryFail());
        yield put(pushPopup({
            message: translate.isEnglish ? "Xóa thất bại" : "Delete failed",
            type: "ERROR"
        }))
        yield put(hideModal());
    }
}


export default function* historySaga() {
    yield throttle(3000,getHistory.type, getHistorySaga)
    yield takeLatest(getHistoryPublic.type, getHistoryPublicSaga)
    yield takeLatest(createHistory.type, createHistorySaga)
    yield takeLatest(putHistory.type, updateHistorySaga)
    yield takeLatest(deleteHistory.type, deleteHistorySaga)
}