import React from "react";
import ContactBottom from "../containers/Home/ContactBottom";
import SlideCompany from "../containers/Home/SlideCompany";
import Topic from "../containers/Home/Topic";
import Banner from "./../containers/Home/Banner";
function HomePage() {
  return (
    <div>
      <div>
        <Banner />
        <div className="">
          <Topic />
        </div>
        <SlideCompany />
        <ContactBottom />
      </div>
    </div>
  );
}

export default HomePage;
