import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getNewsActive } from "../../reducers/news";
import NewsItem from "../../components/NewsItem";
export default function Banner() {
  const dispatch = useAppDispatch();
  const newsData = useAppSelector((state) => state.newsSlice);
  const translate = useAppSelector((state) => state.translateSlice);

  useEffect(() => {
    dispatch(getNewsActive());
  }, []);

  return newsData.newsActiveList.length > 0 ? (
    <div className="bg-primary pt-[50px] pb-[20px]  2xl:h-auto sc991:h-auto md:w-auto sc991:px-[24px] sc<992:px-[24px] 2xl:px-[162px] text-white-color bannerHome sc>768:h-auto sc>768:min-h-max sc>768:max-h-max">
      <div className="grid lg:grid-cols-2 lg:gap-[54px] lssm:grid-cols-1">
        <Link to={`${newsData.newsActiveList[0].id}`}>
          <div className=" xl:h-[300px] 2xl:w-[777px] 2xl:h-[400px] w-1920:h-[474px] md:w-full  sc991:w-full max-w-full rounded-[10px] overflow-hidden">
            <img
              src={newsData.newsActiveList[0].avatarUrl}
              alt="news1"
              className="w-full object-cover h-full"
            />
          </div>
          <div>
            {/* <p className="my-[28px]">{getDate(newsData.newsActiveList[0].createdDate+"")} - Anfico</p> */}
            <h3 className="2xl:text-[32px] mt-[12px] 2xl:mt-[28px] font-bold mb-[12px] w-1920:mb-[30px] line-clamp-2 lssm:text-px16 lssm:text-left sm:text-left  md:text-px28 sc991:text-[32px] ">
              {translate.isEnglish
 
                  ? newsData.newsActiveList[0].titleVi
                : newsData.newsActiveList[0].titleEn
               }
            </h3>

            <p className="lssm:text-px14 2xl:text-px16 line-clamp-3  xl:line-clamp-2 w-1920:line-clamp-3 sc991:text-px16 sc991:text-left">
              {translate.isEnglish
                  ? newsData.newsActiveList[0].descriptionVi
                  : newsData.newsActiveList[0].descriptionEn
              }
            </p>
          </div>
        </Link>

        <div className="sc991:mt-[53px] text-white-color">
          {newsData.newsActiveList[1] && (
              <NewsItem news={newsData.newsActiveList[1]} type={true} />

          )}
          {newsData.newsActiveList[2] && (
              <NewsItem news={newsData.newsActiveList[2]} type={true} />
          )}
          {newsData.newsActiveList[3] && (
              <NewsItem news={newsData.newsActiveList[3]} type={true} />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="h-[30px]"></div>
  );
}
