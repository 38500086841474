import HttpService from "../configs/api";
import { News, Option, ResponsiveData } from "../types";
import getApi from "./getApi";


const newService = {
    createNews: (news:News):Promise<News> => {
        const url = getApi("news");
            return HttpService.axiosClient.post(url, news);
    },
    editNews: (news:News):Promise<News> => {
        const url = getApi("news");
            return HttpService.axiosClient.put(url, news);
    },
    getNews: (option:Option):Promise<ResponsiveData<News>> => {
        const url = getApi("news");
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    },
    getById: (id:number):Promise<News> => {
        const url = getApi(`news/detail/${id}`);
        return HttpService.axiosClient.get(url)
    },
    deleteNews: (ids:number[]):Promise<boolean> => {
        const url = getApi("news");
        return HttpService.axiosClient.delete(url, {data: ids})
    },
    search: ({keyword, option}:{keyword:string, option:Option}):Promise<ResponsiveData<News>> => {
        const url = getApi("news/search/"+keyword)
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    }
}

export default newService;