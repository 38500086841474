import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// import {ModalState} from "../types"
import {ReactNode} from 'react'

// Define a type for the slice state
interface ModalState {
    isShow: boolean,
    Element: ReactNode
}

// Define the initial state using that type
const initialState: ModalState = {
    isShow: false,
    Element: null
}

export const ModalSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    showModal: (state, action:PayloadAction<ReactNode>) => {
      state.isShow = true
      state.Element = action.payload
    },
    hideModal: (state) => {
        state.isShow = false
      state.Element = null
    },
  
  },
})

export const { showModal, hideModal } = ModalSlice.actions

export default ModalSlice.reducer