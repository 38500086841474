import React from "react";

function Loadding() {
  return (
    <div className="loading-container">
      <div className="loading"></div>
      <div className="loading-text">loading</div>
    </div>
  );
}

export default Loadding;
