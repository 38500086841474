import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Option, ResponsiveData, User } from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface UserState {
    listUsers: User[],
    total: number,
    currentUser: User | null,
    isLoading: boolean,
    currentPage: number,
    width: number
}

// Define the initial state using that type
const initialState: UserState = {
    listUsers: [],
    total: 1,
    currentUser: JSON.parse(localStorage.getItem("account")+""),
    isLoading: false,
    currentPage: 1,
    width: window.innerWidth
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload
    }
    ,
    getUser: (state, action:PayloadAction<Option>)=> {
        state.isLoading = true
    },
    getUserSuccess: (state, action:PayloadAction<ResponsiveData<User>>)=> {
        state.listUsers = action.payload.list;
        state.total = action.payload.total
        state.isLoading = false

    },
    getUserFail: (state) => {
        state.isLoading = false

    },
    createUser: (state, action:PayloadAction<User>) => {
        state.isLoading = true;

    },
    createUserSuccess: (state, action:PayloadAction<User>)=> {
       if(state.listUsers.length >= 10) {
        state.listUsers.pop();
       }
        state.listUsers = [action.payload, ...state.listUsers]
        state.isLoading = false
    },
    createUserFail: (state)=> {
        state.isLoading = false
    },
    getCurrentUser: (state) => {

    },
    getCurrentUserSuccess: (state, action:PayloadAction<User>) => {
        state.currentUser = action.payload
        localStorage.setItem("account", JSON.stringify(action.payload))
    },
    deleteUserSlice: (state, action:PayloadAction<string>) => {
        state.isLoading = true
    },
    deleteUserSuccess: (state, action:PayloadAction<string>) => {
        state.listUsers = state.listUsers.filter(item => item.id !== action.payload);
        state.isLoading = false

    },
    deleteUserFail: (state) => {
        state.isLoading = false
    },
    searchUserReduce: (state, action:PayloadAction<{keyword: string, option:Option}>) => {
        state.isLoading = true
    },
    searchUserSuccess: (state, action:PayloadAction<ResponsiveData<User>>) => {
        state.listUsers = action.payload.list;
        state.total = action.payload.total
        state.isLoading = false

    },
    searchUserFail: (state) => {
        state.isLoading = false
    },
    updateCurrentUser: (state, action:PayloadAction<any>) => {
            state.isLoading = true
    },
    updateCurrentUserSuccess: (state, action:PayloadAction<User>) => {
        const user = {
            ...state.currentUser,
            ...action.payload
        }
        state.currentUser = user
        localStorage.setItem("account", JSON.stringify(user))
        state.isLoading = false
    },
    updateCurrentUserFail: (state) => {
        state.isLoading = false
    },
    logoutUser: (state)=> {
        state.currentUser = null;
    },
    activeUser: (state, action:PayloadAction<any>)=> {
        
    },
    activeUserSuccess: (state, action:PayloadAction<any>) => {
        state.listUsers.forEach((user) => {
            if(user.id === action.payload) {
                user.enabled = !user.enabled;
                return;
            }
        })
    }
    


  },
})

export const {getUser, getUserSuccess,  getUserFail, createUser
    , createUserSuccess, createUserFail, getCurrentUser, getCurrentUserSuccess,
     deleteUserFail, deleteUserSlice, deleteUserSuccess,
      searchUserReduce, searchUserSuccess, searchUserFail,
    updateCurrentUser, updateCurrentUserFail, updateCurrentUserSuccess,
    logoutUser,
    setCurrentPage,
    activeUser, activeUserSuccess
    } = userSlice.actions

export default userSlice.reducer