import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/modules/grid";
import "swiper/css/navigation";
import { useAppSelector } from "../../hooks/hook";
import { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";
import { useState } from "react";

export default function SliderBannerHome() {
    const banner = useAppSelector(state => state.bannerSlice);
    const [activeThumb, setThumbActive] = useState<any>(null);
    return (
        <div className="relative w-full min-h-full h-full max-w-full max-h-full">
                <Swiper
                slidesPerView={1}
                thumbs={{swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null}}
    
                spaceBetween={0}
                initialSlide={1}

                // slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={true}
                autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                }}

                modules={[ Navigation, Autoplay, Thumbs, FreeMode]}
                className="min-w-full min-h-full"
            >

                {
                    banner.banners.map((item, index)=> {            
                        return (

                            <SwiperSlide  key={item.id} className="min-w-full min-h-full  bg-slate-500">
                                <img className="banner_home_primary w-full  object-cover" src={item?.imageUrl ?? ""} alt={""}  />
                            
                            </SwiperSlide>  
                    
                        )                 
                    })
                }
                    
                
            </Swiper>
                <div className="absolute max-w-fit  bottom-1 left-[50%] translate-x-[-50%] z-[3]">
                    
                <Swiper
                    slidesPerView={banner.banners.length}
                    // spaceBetween={30}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    // navigation={true}
                    initialSlide={1}
                    freeMode={true}
                    onSwiper={setThumbActive}
                    watchSlidesProgress={true}
                    modules={[ Navigation, Thumbs, FreeMode]}
                    className="h-[20px]   swiper-banner-home"
                >
                    {
                        banner.banners.map((item:any, index) => {
                            return   <SwiperSlide key={index} className="w-full h-[3px]">
                                            <div className="w-[12px] rounded-[50%] h-[12px] mr-[12px] bg-[#ccc] cursor-pointer">

                                            </div>
                                    </SwiperSlide>
                        })
                    }
                </Swiper>
                </div>
        
        </div>
    )
}