import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import convertToHtml from "../../common/convertoHtml";
import Loadding from "../../components/Loadding/Loadding";
import { useAppSelector } from "../../hooks/hook";
import newService from "../../services/newService";
import { News } from "../../types";

export default function NewsDetail() {
    const [news, setNews] = useState<News>()
    const translate = useAppSelector(state => state.translateSlice);
    const param = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=> {
      setIsLoading(true)
        newService.getById(parseInt(param.id+"")).then((data) => {
            setNews(data)

            setIsLoading(false)
        }).catch((error) => {
            
      setIsLoading(false)
            const {status} = error.response;
            if(status === 404) {
            navigate("/tin-tuc");
            }

        })    
    }, [param.id])
    
    return (
        !isLoading ? (
        <div className="lssm:mt-[24px] md:mt-[99px] md:pr-[66px] mb-[120px]">
        <div className="mb-[53px]">
            <h3 className="lssm:text-px20 md:text-[28px] xl:text-[32px] font-bold text-text-primary">{translate.isEnglish ? news?.titleVi : news?.titleEn}</h3>
   
        </div>
        <div className="mb-[60px]">
            <p className="lssm:text-px16 font-bold">{translate.isEnglish ? news?.descriptionVi : news?.descriptionEn}</p>
        </div>
        {
             news ? (
                <div className="lssm:text-px14 md:text-px16 font-normal" dangerouslySetInnerHTML={{__html: translate.isEnglish ? convertToHtml(news?.contentVi+"") : convertToHtml(news?.contentEn+"")}}>
                </div>
                ): <></>
        }
         </div>
            
        ) : <div>
            <Loadding/>
        </div>

    )
}
