import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Device, LoginPayload, Option, ResponsiveData, User } from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface DeviceState {
    devices: Device[],
    isLoading: boolean
    total: number
    currentPage:number
}

// Define the initial state using that type
const initialState: DeviceState = {
    devices: [],
    isLoading: false,
    total: 1,
    currentPage: 1
}

export const DeviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setCurrentPage: (state, action:PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        getDevice: (state, payload: PayloadAction<Option>) => {
            state.isLoading = true;
        },
        getDeviceSuccess: (state, action: PayloadAction<ResponsiveData<Device>>) => {
            state.devices = action.payload.list
            state.total = action.payload.total
            state.isLoading = false
        },
        getDeviceFail: (state) => {
            state.isLoading = false
        },
        createDevice: (state, action: PayloadAction<Device>) => {
            state.isLoading = true
        },
        createDeviceSuccess: (state, action: PayloadAction<Device>) => {
            state.devices = [action.payload, ...state.devices]
            state.isLoading = false
        },
        createDeviceFail: (state) => {
            state.isLoading = false
        },
        deleteDevice: (state, action: PayloadAction<number>) => {
            state.isLoading = true
        },
        deleteDeviceSuccess: (state, action: PayloadAction<number>) => {
            let index = state.devices.findIndex(device => device.id === action.payload);
            state.devices.splice(index, 1)
            state.isLoading = false
        },
        deleteDeviceFail: (state) => {
            state.isLoading = false
        },
        updateDevice: (state, action: PayloadAction<Device>) => {
            state.isLoading = true
        },
        updateDeviceSuccess: (state, action: PayloadAction<Device>) => {
            const index = state.devices.findIndex(state => state.id === action.payload.id)
            state.devices.splice(index, 1, action.payload)
            state.isLoading = false
        },
        updateDeviceFail: (state) => {
            state.isLoading = false
        },
        searchDevice: (state, action: PayloadAction<{ keyword: string, option: Option }>) => {
            state.isLoading = true
        },
        searchDeviceSuccess: (state, action: PayloadAction<ResponsiveData<Device>>) => {
            state.devices = action.payload.list
            state.total = action.payload.total
            state.isLoading = false
        },
        searchDeviceFail: (state) => {
            state.isLoading = false
        }

    }
})

export const { getDevice, getDeviceSuccess, getDeviceFail, createDevice,
    createDeviceFail, createDeviceSuccess, deleteDevice, deleteDeviceSuccess,
    deleteDeviceFail, updateDevice, updateDeviceFail, updateDeviceSuccess,
    searchDevice, searchDeviceFail, searchDeviceSuccess,
    setCurrentPage
} = DeviceSlice.actions

export default DeviceSlice.reducer