import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Candidate, Option, Recruit, ResponsiveData } from '../types'

// import {ModalState} from "../types"

// Define a type for the slice state
interface CareerState {
    listCareer: Recruit[],
    total: number
    isLoading: boolean
    listCandidates:Candidate[]
    totalCandidates:number
    currentPage: number;

}

// Define the initial state using that type
const initialState: CareerState = {
    listCareer: [],
    total: 0,
    isLoading: false,
    listCandidates: [],
    totalCandidates: 0,
    currentPage: 1
}

export const careerSlice = createSlice({
  name: 'career',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload;
    },

    createCareerSuccess: (state, action:PayloadAction<Recruit>) => {
        state.listCareer = [action.payload, ...state.listCareer]

    },
    getCareerReduce: (state, action:PayloadAction<Option>) => {
            state.isLoading = true
    },
    getCareerSuccess: (state, action:PayloadAction<ResponsiveData<Recruit>>)=> {

        state.listCareer = action.payload.list
         state.total = action.payload.total;
        state.isLoading = false;

    },
    getCareerFail: (state)=> {
        state.isLoading = false;
    },
    updateCareer: (state, action:PayloadAction<Recruit>) => {
        state.isLoading = true
    },
    updateCareerSuccess: (state, action:PayloadAction<Recruit>)=> {
            const index = state.listCareer.findIndex(career => career.id === action.payload.id);

            if(index !== -1) {
                state.listCareer.splice(index, 1, action.payload);
            }
            state.isLoading = false
    },
    updateCareerFail: (state) => {
        state.isLoading = false
    },
    getCandidates : (state, action:PayloadAction<Option>) => {
        state.isLoading = true
    },
    getCandidatesSuccess: (state, action:PayloadAction<ResponsiveData<Candidate>>)=> {
        state.listCandidates = action.payload.list
        state.totalCandidates = action.payload.total
        state.isLoading = false
    },
    getCandidatesFail: (state) => {
        state.isLoading = false
    },
    deleteCareer: (state, action:PayloadAction<number>)=> {
        state.isLoading = true;
    },
    deleteCareerSuccess: (state, action:PayloadAction<number>) => {
        state.listCareer = state.listCareer.filter(item => item.id !== action.payload);
        state.isLoading = false;
        
    },
    deleteCareerFail: (state)=> {
        state.isLoading = false;
    },
    getCandidateFilter: (state, action:PayloadAction<{id:number, option:Option}>) => {
        state.isLoading = true
    },
    getCandidateFilterSuccess: (state, action:PayloadAction<ResponsiveData<Candidate>>) => {
        state.isLoading = false
        state.listCandidates = action.payload.list
        state.totalCandidates = action.payload.total

    },
    getCandidateFilterFail: (state) => {
        state.isLoading = false
    },
    searchCareer: (state, action:PayloadAction<{keyword:string, type: string ,option:Option}>) => {
        state.isLoading = true
    },
    searchCareerSuccess: (state, action:PayloadAction<ResponsiveData<Recruit>>) => {
        state.listCareer = action.payload.list
        state.total = action.payload.total
        state.isLoading = false
    },
    searchCareerFail: (state) => {
        state.isLoading = false
    }

  },
})

export const { createCareerSuccess, getCareerReduce, 
    getCareerSuccess, getCareerFail, updateCareer, updateCareerSuccess, updateCareerFail,
     getCandidates, getCandidatesSuccess, getCandidatesFail, deleteCareer, deleteCareerFail,
      deleteCareerSuccess, getCandidateFilterSuccess, getCandidateFilter,
      getCandidateFilterFail, searchCareer, searchCareerFail, searchCareerSuccess,
      setCurrentPage
    } = careerSlice.actions

export default careerSlice.reducer