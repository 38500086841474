import { Outlet } from "react-router-dom";
import Banner from "../containers/About/Banner";

export default function About() {
  return (
    <div>
      <Banner />
      <div className="lssm:px-[24px] md:px-[80px] xl:px-[100px] w-1920:px-[162px]">
        <Outlet />
      </div>
    </div>
  );
}
