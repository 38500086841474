import { createSlice } from '@reduxjs/toolkit'
import  { PayloadAction } from '@reduxjs/toolkit'
import { Banner } from '../types/banner'
// import {ModalState} from "../types"

// Define a type for the slice state
interface BannerState {
    banners: Banner[],
    isLoading: boolean
}

// Define the initial state using that type
const initialState: BannerState = {
    banners: localStorage.getItem("banner") ?  JSON.parse(localStorage.getItem("banner")+"") : [],
    isLoading: false
}

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
        createBanner: (state, action:PayloadAction<Banner[]>)=> {
            state.isLoading = true
        },
        createBannerSuccess: (state, action:PayloadAction<Banner[]>)=> {
            state.banners = [...action.payload,...state.banners];
            state.isLoading = false
        },
        createBannerFail: (state)=> {
            state.isLoading = false

        },
        getBanner: (state)=> {
            state.isLoading = true
        },
        getBannerSuccess: (state, action:PayloadAction<Banner[]>) => {
            state.banners = action.payload
            localStorage.setItem('banner', JSON.stringify(action.payload));
            state.isLoading = false
        },
        getBannerFail: (state) => {
            state.isLoading = false
        },
        deleteBanner: (state, action:PayloadAction<number>) => {
            state.isLoading = false
        },
        deleteBannerSuccess: (state, action:PayloadAction<number>) => {
            let bannersDelete = state.banners.filter(item => item.id !== action.payload);
            state.banners = bannersDelete;
            localStorage.setItem('banner', JSON.stringify(bannersDelete));
            state.isLoading = false
        },
        deleteBannerFail: (state) => {
            state.isLoading = false;
        }   
  
  },
})

export const {createBanner, createBannerSuccess, createBannerFail, getBanner, getBannerSuccess, getBannerFail, deleteBanner, deleteBannerFail, deleteBannerSuccess} = bannerSlice.actions

export default bannerSlice.reducer