import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Option, Project, ResponsiveData } from "../types";



// Define a type for the slice state
interface ProjectState {
    projects: Project[],
    currentPage: number
    total: number,
    isLoading: boolean
    projectListActive: Project[]
    projectsHome: Project[],
    currentPageHome: number,
    totalHome: number,
}

// Define the initial state using that type
const initialState: ProjectState = {
    projects: [],
    currentPage: 1,
    total: 0,
    isLoading: false,
    projectListActive: localStorage.getItem("project") ? JSON.parse(localStorage.getItem("project")+"") : [],
    projectsHome: [],
    currentPageHome: 0,
    totalHome: 12,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload;
    },
    setCurrentPageHome: (state, action:PayloadAction<number >) => {
        state.currentPageHome = action.payload
    }
    ,
   getProjectsActive: (state) => {
      
    },
   getProjectsActiveSuccess: (state, action:PayloadAction<Project[]>) => {
        state.projectListActive = action.payload;
       localStorage.setItem("project", JSON.stringify(action.payload))  
    },
    
    getProjects: (state, option:PayloadAction<Option>) => {
        state.isLoading = true
    },
    getProjectsSuccess: (state, option:PayloadAction<ResponsiveData<Project>>) => {
        state.projects = option.payload.list;
        state.total = option.payload.total;
        state.isLoading = false
    },
    getProjectsFail: (state) => {
        state.isLoading = false
    },
    getProjectHome: (state, action:PayloadAction<Option>) => {
        
    },
    getProjectHomeSuccess: (state, action:PayloadAction<ResponsiveData<Project>>) => {
        state.totalHome = action.payload.total
        state.projectsHome = [...state.projectsHome, ...action.payload.list];
    }
  },
});

export const {setCurrentPage,getProjects, getProjectsFail, getProjectsSuccess,
    getProjectsActive, getProjectsActiveSuccess,
    getProjectHome, getProjectHomeSuccess,
    setCurrentPageHome
             
} =
  projectSlice.actions;

export default projectSlice.reducer;
