import {  News, Option, ResponsiveData } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { deleteNews, deleteNewsFail, deleteNewsSuccess, getNews, getNewsActive, getNewsActiveSuccess, getNewsFail, getNewsSuccess, searchNews, searchNewsFail, searchNewsSuccess } from '../reducers/news';
import newService from '../services/newService';
import { pushPopup } from '../reducers/popupSlice';
import { hideModal } from '../reducers/modal';
import { TranslateState } from '../reducers/translate';
import { RootState } from '../configs/redux/store';


function* getNewsSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<News> = yield call(newService.getNews, payload);
        yield put(getNewsSuccess(res))
    }catch(error) {
        yield put(getNewsFail())

    }
}

function* deleteNewsSaga({payload}:PayloadAction<number[]>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  
    try {
        const res:Boolean = yield call(newService.deleteNews, payload);
        if(res) {
            yield put(deleteNewsSuccess(payload))
            yield put(hideModal())

            yield put(pushPopup({
                type: "SUCCESS",
                message: translate.isEnglish ? "Xóa thành công." : "Delete successfully."
            }))
        }else {
            yield put(deleteNewsFail())
            yield put(hideModal())
            yield put(pushPopup({
                type: "ERROR",
                message:  translate.isEnglish ? "Xóa thất bại." : "Delete failed"
            }))
        }
    }catch(error) {
        yield put(deleteNewsFail())
        yield put(hideModal())

        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Xóa thất bại!" : "Delete failed"
        }))
    }
}

function* getNewsActiveSaga() {
    try {
        const res:ResponsiveData<News> = yield call(newService.getNews, {page: 1, limit: 4});
        yield put(getNewsActiveSuccess(res.list));
    }catch(error) {

    }
}

function* searchNewsSaga({payload}:PayloadAction<{keyword:string, option:Option}>) {
    try {
        if(payload.keyword.trim() !== "") {
            const res:ResponsiveData<News> = yield call(newService.search, payload);
            yield put(searchNewsSuccess(res));
        }else {
            yield put(getNews(payload.option))
        }
    }catch(error) {
        yield put(searchNewsFail());

    }
}


export default function* newsSaga() {
    yield takeLatest(getNews.type, getNewsSaga)
    yield takeLatest(deleteNews.type, deleteNewsSaga)
    yield takeLatest(getNewsActive.type, getNewsActiveSaga)
    yield debounce(1000, searchNews.type, searchNewsSaga)
}