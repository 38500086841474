import { createSlice } from '@reduxjs/toolkit'
import  { PayloadAction } from '@reduxjs/toolkit'
import { Banner } from '../types/banner'
// import {ModalState} from "../types"

// Define a type for the slice state
interface PartnerState {
    partner: Banner[],
    isLoading: boolean
}

// Define the initial state using that type
const initialState: PartnerState = {
    partner: localStorage.getItem('partner') ? JSON.parse(localStorage.getItem("partner")+"") : [],
    isLoading: false
}

 const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
        createPartner: (state, action:PayloadAction<Banner[]>)=> {
            state.isLoading = true
        },
        createPartnerSuccess: (state, action:PayloadAction<Banner[]>)=> {
            state.partner = [...action.payload,...state.partner];
            localStorage.setItem("partner", JSON.stringify([...action.payload,...state.partner]));
            state.isLoading = false
        },
        createPartnerFail: (state)=> {
            state.isLoading = false

        },
        getPartner: (state)=> {
            state.isLoading = true
        },
        getPartnerSuccess: (state, action:PayloadAction<Banner[]>) => {
            state.partner = action.payload
            localStorage.setItem("partner", JSON.stringify(action.payload));

            state.isLoading = false
        },
        getPartnerFail: (state) => {
            state.isLoading = false
        },
        deletePartner: (state, action:PayloadAction<number>) => {
            state.isLoading = false
        },
        deletePartnerSuccess: (state, action:PayloadAction<number>) => {
            let partners = state.partner.filter(item => item.id !== action.payload);
            state.partner = partners
            localStorage.setItem("partner", JSON.stringify(partners));

            state.isLoading = false
        },
        deletePartnerFail: (state) => {
            state.isLoading = false;
        }   
  
  },
})

export const {createPartner, createPartnerSuccess, createPartnerFail, getPartner, getPartnerSuccess, getPartnerFail, deletePartner, deletePartnerFail, deletePartnerSuccess} = partnerSlice.actions

export default partnerSlice.reducer