import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Album, Option, ResponsiveData, TopicImage} from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface AlbumState {
   albums: Album[]
   isLoading: boolean
   total: number,
   albumImages: TopicImage[],
   currentPage: number
}

// Define the initial state using that type
const initialState: AlbumState = {
    albums: [],
    isLoading: false,
    total: 0,
    albumImages: [],
    currentPage: 1
}

export const albumSlice = createSlice({
  name: 'album',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload;
    },
    createAlbum: (state, action:PayloadAction<Album>) => {
        state.isLoading = true
    },
    createAlbumSuccess: (state, action:PayloadAction<Album>)=> {
        state.albums = [action.payload, ...state.albums];
        state.isLoading = false
    },
    createAlbumFail: (state)=> {
            state.isLoading = false
    },
    getAlbum: (state) => {
        state.isLoading = false
    },
    getAlbumSuccess: (state, action:PayloadAction<Album[]>) => {
        state.albums = action.payload;
        state.isLoading = false
    },
    getAlbumFail: (state) => {
        state.isLoading = false
    },
    getAlbumImage: (state, action:PayloadAction<number>) => {
        state.isLoading = true;
    },
    getAlbumImageSuccess: (state, action:PayloadAction<TopicImage[]>) => {
     
        state.isLoading = false;
        state.albumImages = action.payload
    },
    getAlbumImageFail: (state) => {
        state.isLoading = false;
    },
    createImageToAlbum: (state, action:PayloadAction<TopicImage[]>)=> {
        state.albumImages = [...action.payload, ...state.albumImages];
    },
    deleteImageAlbum: (state, action:PayloadAction<number[]>) => {
        action.payload.forEach((image, index) => {
            let id = state.albumImages.findIndex(item => item.id === image);
            if(index !== -1) {
                state.albumImages.splice(id, 1);
            }

        })
    },
    deleteAlbum: (state, action:PayloadAction<number>) => {
        let index = state.albums.findIndex(item => item.id === action.payload)
        if(index !== -1) {
            state.albums.splice(index, 1);
        }
    }
  },
})

export const {createAlbum, createAlbumSuccess,createAlbumFail, 
    getAlbum, getAlbumFail, getAlbumSuccess, getAlbumImage, 
    getAlbumImageFail, getAlbumImageSuccess, createImageToAlbum, 
    deleteImageAlbum, deleteAlbum,
    setCurrentPage

} = albumSlice.actions

export default albumSlice.reducer