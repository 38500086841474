import {TiDelete} from"react-icons/ti"
import { useAppDispatch } from "../../hooks/hook"
import { hideModal } from "../../reducers/modal";

type Props = {
    Map: JSX.Element
}

export default function ModalMap({Map}:Props) {
    const dispatch = useAppDispatch();
    return (
        <div className="h-[95vh] bg-white w-[900px] max-w-[95vw] lssm:p-[12px] md:p-[24px] rounded-md relative">
            <div onClick={() => dispatch(hideModal())} className="absolute top-[-10px] right-[-15px] cursor-pointer shadow-sm shadow-black rounded-[100rem] p-[2] bg-white-color flex justify-center items-center text-[28px]">
                <TiDelete />
            </div>
            {Map}
        </div>
    )
}