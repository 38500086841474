import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import SliderBannerHome from "../../components/SliderBannerHome";
import { useAppDispatch } from "../../hooks/hook";
import { getBanner } from "../../reducers/banner";

export default function Banner() {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBanner());
  }, []);



  return (
    <div className="flex items-center justify-center text-center banner_home_primary relative text-white-color">
      <div className="flex flex-col items-center justify-end pb-[37px] h-full my-auto  sc991:absolute z-[2] lssm:px-[24px] sm:px-[40px] md:px-[80px] lg:px-[120px] 2xl:px-[242px]">
        <div className="lssm:w-fit xl:w-max lssm:text-[16px] md:text-[20px] font-bold uppercase xl:text-[32px]  2xl:text-[38px] w-1920:text-[48px] relative z-[1]  animate__animated animate__fadeInDown">
          {t("home.banner.title")}
        </div>
        <p className="lssm:text-px14 md:text-px20 sc>768:px[24px] lssm:mb-[14px] md:mb-[28px] lssm:leading-4 md:leading-7 sc991:text-white-color animate__animated animate__fadeInUp">
          {t("home.banner.description")}
        </p>
        <Button
          onClick={() => navigate("/lien-he")}
          color="primary"
          className="sc>768:text-px14 uppercase px-[34px] py-[14px] max-w-fit"
        >
          {t("home.banner.button")}
        </Button>
      </div>
      <div className="w-full max-w-full min-h-[100%] inset-0 absolute overflow-hidden banner_home-after">
        <SliderBannerHome />
      </div>
    </div>
  );
}
