import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Contact, Option, ResponsiveData } from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface AuthState {
   contactList: Contact[]
   isLoading: boolean,
   total: number,
   currentPage: number
}

// Define the initial state using that type
const initialState: AuthState = {
    contactList: [],
    isLoading: false,
   total: 0,
   currentPage: 1

}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload
    },
        getContact: (state, action:PayloadAction<Option>) => {
            state.isLoading = true;
        },
        getContactSuccess: (state, action:PayloadAction<ResponsiveData<Contact>>) => {
            state.contactList = action.payload.list
            state.total = action.payload.total
            state.isLoading = false;
        },
        getContactFail: (state) => {
            state.isLoading = false;
        },
        feedBackSuccess: (state, action:PayloadAction<Contact>)=> {
            const index = state.contactList.findIndex(contact => contact.id === action.payload.id);
            state.contactList.splice(index, 1, action.payload);
        },
        deleteContactReduce: (state, action:PayloadAction<number>) => {
            state.isLoading = true;
        },
        deleteContactSuccess: (state, action:PayloadAction<number>)=> {
            state.contactList = state.contactList.filter(contact => contact.id !== action.payload);
            state.isLoading = false
        },
        deleteContactFail: (state) => {
            state.isLoading = false;
        },
        searchContact: (state, action:PayloadAction<{keyword:string, option:Option}>) => {
            state.isLoading = true;
        },
        searchContactSuccess: (state, action:PayloadAction<ResponsiveData<Contact>>) => {
            state.contactList = action.payload.list
            state.total = action.payload.total
            state.isLoading = false;
        },
        searchContactFail: (state) => {
            state.isLoading = false
        }
  
  },
})

export const {getContact, getContactSuccess, getContactFail,
     feedBackSuccess, deleteContactReduce, deleteContactSuccess,
      deleteContactFail, setCurrentPage,
      searchContact, searchContactSuccess, searchContactFail
    } = contactSlice.actions

export default contactSlice.reducer