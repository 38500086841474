import HttpService from "../configs/api";
import getApi from "../services/getApi"
import { Option, Project, ResponsiveData } from "../types";
import { projecResult, projectTypePost, projecType, searchProjecParam } from "../typeProps/Productype";



const projectServiece = {
    get: (option:Option):Promise<ResponsiveData<Project>> => {
        const url = getApi("projects");
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}})
    },
    getById: (id:number):Promise<Project> => {
        const url = getApi("project/detail/"+id);

        return HttpService.axiosClient.get(url);
    },

    getProjectHome: (option:Option):Promise<ResponsiveData<Project>> => {
        const url = getApi("project/home");
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    },
    getProjectActive: ():Promise<Project[]> => {
        const url = getApi("project/priority");

        return HttpService.axiosClient.get(url);
    },
    searchProject:(data: searchProjecParam):Promise<projecResult> => { 
        const url = getApi(`project/search`);
        return HttpService.axiosClient.get(`${url}/${data.type}/${data.keySearch}`,{params: {page: data.option.page, limit: data.option.limit}});
    },
    putProject:(id:number):Promise<projecType> =>{
        const url = getApi(`project/priority/${id}`);
          return HttpService.axiosClient.put(url);
    },
    deleteProject:(id:number):Promise<boolean>=> {
        const url = getApi(`project/${id}`);
        return HttpService.axiosClient.delete(url)
    },
    postProject: (data:projectTypePost):Promise<projecType> => {
        const url = getApi(`project`);
        return HttpService.axiosClient.post(url,data) 
    },
    editProject: (data: any):Promise<Project> => {
        const url = getApi(`project`);
        return HttpService.axiosClient.put(url, data)
    }

}


export default projectServiece