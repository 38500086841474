
import HistoryItem from './HistoryItem';

type Props = {
    historyData: any[]
}

export default function HistoryWeb({historyData}:Props) {


    return   <div className="relative h-auto lssm:my-[24px] md:my-[40px] xl:my-[80px]">
    {
        historyData.map((his, index)=> {
                return <HistoryItem key={index} index={index} his={his} />
        })
    }


        <div className="h-full w-[2px] bg-primary absolute  left-[50%] top-0 translate-x-[-50%] my-[70px]">

        </div>
    </div>
}

