import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

type propsDirection = {
  content: string;
  isClick?: boolean;
  isweb?: boolean;
  onclickFunc?: () => void;
};
function Diretional({ content, isweb, isClick, onclickFunc }: propsDirection) {
  const { t, i18n } = useTranslation();
  return (
    <div
      onClick={onclickFunc}
      className={clsx(
        "lg:min-w-[150px] lg:max-w-[300px]  w-2/4 border-t rounded-t-xl w-1920:px-5 py-3 flex items-center justify-center 2xl:text-xl sm-390:text-base lsm-320:text-sm text-xs text-center font-bold",
        isClick ? "bg-text-primary text-white" : "text-text-primary bg-white",
        isweb && "bg-bg_blue_bold text-white"
      )}
    >
      {t(`${content}`)}
    </div>
  );
}

export default Diretional;
