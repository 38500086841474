import { Album, LoginPayload, Option, TopicImage } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { login, loginFail, loginSuccess } from '../reducers/auth';
import authService from '../services/auth';
import { createAlbum, createAlbumFail, createAlbumSuccess, getAlbum, getAlbumFail, getAlbumImage, getAlbumImageFail, getAlbumImageSuccess, getAlbumSuccess } from '../reducers/album';
import albumService from '../services/album';


function* getAlbumSaga() {
    try {
        const res:Album[] = yield call(albumService.getAlbum);
        yield put(getAlbumSuccess(res)) 
    }catch(error) {
        yield put(getAlbumFail());
    }
}

function* getAlbumImageSaga({payload}:PayloadAction<number>) {
    try {
        const res:TopicImage[] = yield call(albumService.getImagesById, payload);
        yield put(getAlbumImageSuccess(res));
     }catch(error) {
        yield put(getAlbumImageFail());
     }
}


export default function* albumSaga() {
    yield takeLatest(getAlbum.type, getAlbumSaga)
    yield takeLatest(getAlbumImage.type, getAlbumImageSaga)
}