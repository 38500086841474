import HttpService from "../configs/api"
import { ResponsiveData } from "../types"
import { Banner } from "../types/banner"
import getApi from "./getApi"


const partnerService = {
    create: (banner: Banner[]):Promise<ResponsiveData<Banner>> => {
        const url = getApi("partner")
            return HttpService.axiosClient.post(url, banner);
    },
    find: ():Promise<Banner[]> => {
        const url = getApi("partner")
        return HttpService.axiosClient.get(url)
    },
    delete: (id:number):Promise<boolean> => {
        const url = getApi(`partner/image/${id}`);

        return HttpService.axiosClient.delete(url)


    }
}


export default partnerService