import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// Define a type for the slice state
export interface TranslateState {
  isEnglish: boolean;
}


const initialState: TranslateState = {
  isEnglish:  true 
};

export const translateSlice = createSlice({
  name: "translate",
  initialState,
  reducers: {
    setTranslate: (state, action:PayloadAction<string>) => {
      state.isEnglish = action.payload === "vi" ? true : false;
    },
  },
});

export const { setTranslate } = translateSlice.actions;
export default translateSlice.reducer;
