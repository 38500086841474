type LableProps = {
  content: string;
};

export const LableContent = ({ content }: LableProps) => {
  return (
    <p className=" text-primary xl:mb-9 mb-5 sm-480:text-xl text-sm font-bold">
      {content}
    </p>
  );
};
