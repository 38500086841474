import {  Option, ResponsiveData, User } from './../types';
import {  PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, debounce, select } from 'redux-saga/effects';
import { activeUser, activeUserSuccess, createUser, createUserFail, createUserSuccess, deleteUserFail, deleteUserSlice, deleteUserSuccess, getCurrentUser, getCurrentUserSuccess, getUser, getUserFail, getUserSuccess, logoutUser, searchUserFail, searchUserReduce, searchUserSuccess, updateCurrentUser, updateCurrentUserSuccess } from '../reducers/user';
import userService from '../services/user';
import { pushPopup } from '../reducers/popupSlice';
import { hideModal } from '../reducers/modal';
import { loginHasToken, logout } from '../reducers/auth';
import { RootState } from '../configs/redux/store';
import { TranslateState } from '../reducers/translate';


function* userSagaMethod({payload}:PayloadAction<Option>) {
    try {

        const res:ResponsiveData<User> = yield call(userService.find, payload);

        yield put(getUserSuccess(res));
    }catch(error) {
     

        yield put(getUserFail());

    }
}


function* createUserMethod({payload}:PayloadAction<User>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);

    try {
        const res:User = yield call(userService.create, payload);
        yield put(createUserSuccess(res));
        yield put(pushPopup({
            type: "SUCCESS",
            message: translate.isEnglish ? "Thêm thành công." : "Add successfully."
        }));

        yield put(hideModal())

    }catch(error:any) {
        yield put(createUserFail());
        yield put(pushPopup({
            type: "ERROR",
            message: error.response.data.title
        }));

    }

}


function* getCurrentUserMethod() {
    try {
        const  res:User = yield call(userService.getProfile);
        yield put(getCurrentUserSuccess(res))
        yield put(loginHasToken());
    }catch(error:any) {
   
        const status = error.response.status;

        if(status === 401 || status === 403) {
            yield put(logout())
            yield put(logoutUser());
            
        }
    }
}

function* deleteUserMethod({payload}:PayloadAction<string>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);

    try {
        const res:boolean = yield call(userService.deleteUser, payload);
    if(res) {
        yield put(deleteUserSuccess(payload))
        yield put(pushPopup({
            type: "SUCCESS",
            message: translate.isEnglish ? "Xóa thành công." : "Delete successfully."
        }))
        yield put(hideModal())

    }else {
        yield put(deleteUserFail())
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Xóa thất bại." : "Delete failed."
        }))
        yield put(hideModal())

    }

    }catch(error:any) {
        yield put(deleteUserFail())
        yield put(pushPopup({
            type: "ERROR",
            message: translate.isEnglish ? "Xóa thất bại." : "Delete failed."
        }))
        yield put(hideModal())

    }
}

function* searchUserMethod({payload}:PayloadAction<{keyword: string, option:Option}>) {
    
    try{
        if(payload.keyword.trim() !== "") {
            const res:ResponsiveData<User> = yield call(userService.searchUser, {keyword: payload.keyword,option:payload.option })

            yield put(searchUserSuccess(res))

        }else {
            yield put(getUser(payload.option))

        }

    }catch(error) {
        yield put(searchUserFail());

    }


}


function* updateCurrentUserSaga({payload}:PayloadAction<User>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);

    try {
        const res:User = yield call(userService.updateUser, payload);
        yield put(updateCurrentUserSuccess(res));
        yield put(pushPopup({
            message: translate.isEnglish ? "Cập nhật thành công." : "Update successful.",
            type: "SUCCESS"
        }))

    }catch(error) {
        yield put(pushPopup({
            message: translate.isEnglish ? "Cập nhật thất bại." : "Update failed.",
            type: "ERROR"
        }))
    }
}

function* activeUserSaga({payload}:PayloadAction<any>){
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    try {
            const res:boolean = yield call(userService.activeUser, payload);

          
            const listUser:User[] = yield select((state:RootState) => state.userSlice.listUsers);
            const userC = listUser.find(user => user.id === payload);
            yield put(pushPopup({
                message: `${userC?.fullname ?? "Tài khoản"} ${userC?.enabled ? (translate.isEnglish ? "chặn" : "lock") : (translate.isEnglish ? "được cấp quyền" : "unclock")}`,
                type:"SUCCESS"
            }))
            yield put(activeUserSuccess(payload));
       
    }catch(error) {
        yield put(pushPopup({
            message: translate.isEnglish ? `Lỗi hệ thống` : "System error",
            type:"ERROR"
        }))
    }
}



export default function* userSaga() {
    yield takeLatest(getUser.type, userSagaMethod)
    yield takeLatest(createUser.type, createUserMethod)
    yield takeLatest(getCurrentUser.type, getCurrentUserMethod)
    yield takeLatest(deleteUserSlice.type, deleteUserMethod)
    yield debounce(1000,searchUserReduce.type, searchUserMethod)
    yield takeLatest(updateCurrentUser.type, updateCurrentUserSaga)
    yield takeLatest(activeUser.type, activeUserSaga)
}