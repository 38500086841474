import clsx from "clsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/hook";
import { navList } from "../Router/NavList";

type Props = {
  isborder?: boolean;
  navOnclick?: () => void;
};
function Menu({ navOnclick, isborder }: Props) {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useAppSelector(state => state.userSlice.currentUser);

  const getPathName = useCallback(
    (listPath: any[]) => {
      const newArr = listPath.map((i) => i.path);

      const isCheck = newArr.some((item) => item === location.pathname);

      return isCheck;
    },
    [location.pathname]
  );
  return (
    <>
      <ul
        className={clsx(
          "flex items-start pb-5 pt-3 flex-col",
          isborder && "border-b border-b-border-gray"
        )}
      >
        {navList.map((item, index) => {
          return !item.children ? (
            
              item.path !== "/quanly/thongtintaikhoan" ? (
                  <li key={`{menukey${index}}`} className="w-full">
                    <NavLink
                      onClick={navOnclick}
                      to={item.path}
                      className={({ isActive }) =>
                        isActive
                          ? "block xl:mx-2 2xl:mx-[18px] text-[#0058DB] 2xl:py-[10px] font-normal py-4 px-5 text-px14"
                          : "block xl:mx-2 2xl:mx-[18px] 2xl:py-[10px] font-normal py-4 px-5 text-px14"
                      }
                    >
                      <div className="w-full flex items-center">
                        <span className="mr-4 w-3 h-auto">{item.icon}</span>
                        {t(`${item.name}`)}
                      </div>
                    </NavLink>
                  </li>

              ) : (
                user && (
                  <li key={`{menukey${index}}`} className="w-full">
                  <NavLink
                    onClick={navOnclick}
                    to={item.path}
                    className={({ isActive }) =>
                      isActive
                        ? "block xl:mx-2 2xl:mx-[18px] text-[#0058DB] 2xl:py-[10px] font-normal py-4 px-5 text-px14"
                        : "block xl:mx-2 2xl:mx-[18px] 2xl:py-[10px] font-normal py-4 px-5 text-px14"
                    }
                  >
                    <div className="w-full flex items-center">
                      <span className="mr-4 w-3 h-auto">{item.icon}</span>
                      {t(`${item.name}`)}
                    </div>
                  </NavLink>
                </li>
                )

              )
            
          ) : (
            <li
              key={index}
              className={clsx(
                "relative w-full block xl:mx-2 2xl:mx-[18px] 2xl:py-[10px] nav-link_mobile font-normal py-4 px-5 text-px14",
                { "text-[#0058DB]": getPathName(item.children) }
              )}
            >
              <div className="w-full flex items-center">
                <span className="mr-4 w-3 h-auto">{item.icon}</span>
                {t(`${item.name}`)}
              </div>
              <ul className="absolute w-[250px] h-auto bg-white-color py-[30px] shadow-xl rounded-md text-text-decoration text-px16 font-normal">
                {item.children.map((crPath, index) => {
                  return (
                    <li key={`crPath${index}`}>
                      <Link
                        className="pl-[30px] text-px14 py-[12px] block"
                        onClick={navOnclick}
                        to={crPath.path}
                      >
                        {t(`${crPath.name}`)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Menu;
