import HttpService from "../configs/api";
import { HistoryState, Option, ResponsiveData } from "../types";
import getApi from "./getApi";



const historyService = {
    createHistory: (data:HistoryState):Promise<HistoryState> => {
        const url = getApi("history");
        return HttpService.axiosClient.post(url, data)

    },
    getHistory: (option:Option):Promise<ResponsiveData<HistoryState>> => {
        const url = getApi("histories");

        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    },

    getHistoryPublic: ():Promise<ResponsiveData<HistoryState>> => {
        const url = getApi("histories");

        return HttpService.axiosClient.get(url, {params: {page: 0, limit: 100}});
    },
    checkYear: (year:number):Promise<boolean> => {
        const url = getApi(`history/check/${year}`)
        return HttpService.axiosClient.get(url);
    },
    put: (data:HistoryState):Promise<HistoryState> => {
        const url = getApi("history");

        return HttpService.axiosClient.put(url, data);
    },
    delete: (id:number):Promise<boolean> => {
        const url = getApi("history/"+id);

        return HttpService.axiosClient.delete(url);
    }
}


export default historyService;