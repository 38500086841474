import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../configs/redux/store";
import {
  addCategory,
  addCategorySuccess,
  deleteCategory,
  deleteCategorySucces,
  editCategory,
  editCategorySuccess,
  getCategory,
  getCategoryFail,
  getCategorySuccess,
} from "../reducers/categorySlice";
import { hideModal, showModal } from "../reducers/modal";
import { pushPopup } from "../reducers/popupSlice";
import { TranslateState } from "../reducers/translate";
import CategoryService from "../services/category";
import { categoryPost, categoryType } from "../typeProps/Productype";

function* getAllCategory() {
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
  try {
    const result: {total: number, list:categoryType[] }= yield call(CategoryService.getAll);
    yield put(getCategorySuccess(result));
  } catch (error) {
    yield put(getCategoryFail(isEnglish ? "Có lỗi không hiển thị được danh mục!" : "There is an error that the category cannot be displayed!"));
  }
}

function* addCategoryFunc({ payload }: PayloadAction<categoryPost>) {
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
  try {
    const result: categoryType = yield call(
      CategoryService.addCategory,
      payload
    );
    yield put(addCategorySuccess(result));
    yield put(
      pushPopup({
        type: "SUCCESS",
        message: isEnglish ? "Thêm danh mục thành công." : "Add successful category",
      })
    );
  } catch (error) {
    yield put(
      pushPopup({
        type: "WARNING",
        message: isEnglish ? "Thêm danh mục thất bại" : "Add failure category",
      })
    );
  }
}

function* deleCategoryFunc ({payload}: PayloadAction<number>){
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
  try {
    const res: boolean = yield call(CategoryService.deleteCategory,payload);
    yield put(deleteCategorySucces(payload))
    yield put(hideModal());
    yield put(pushPopup({
      type:"SUCCESS",
      message: isEnglish ? "Xóa thành công" : "Delete successfully",
    }))
  } catch (error) {
    yield put(pushPopup({
      type: "WARNING",
      message: isEnglish ? "Không xóa được danh mục này" : "This category cannot be deleted",
    }))
  }
}
function*editCategoryFunc({payload}: PayloadAction<categoryType>){
  const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
  try {
    const res:categoryType = yield call(CategoryService.editCategory,payload);
    yield put(editCategorySuccess(res))
    yield put(pushPopup(
      {
        type: "SUCCESS",
        message: isEnglish ? "Sửa thành công" : "Success edited",
        }
      ))
  } catch (error) {
    yield put(pushPopup({
      type: "WARNING",
      message: isEnglish ? "Không sửa được" : "Unfixable",
    }))
  }
}

export default function* categorySage() {
  yield takeLatest(getCategory.type, getAllCategory);
  yield takeLatest(addCategory.type, addCategoryFunc);
  yield takeLatest(deleteCategory.type, deleCategoryFunc);
  yield takeLatest(editCategory.type, editCategoryFunc);
}
