import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SiderightContainer from "../containers/siderightManager/SiderightContainer";
import { useAppSelector } from "../hooks/hook";
import UserService from "../services/UserService";
import Footer from "./Footer";
import HeaderManager from "./HeaderManager";

function LayoutManager() {
  const [overlay, setOverlay] = useState<boolean>(false);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userSlice.currentUser);
  const location = useLocation();

  useEffect(() => {
    if (
      user?.authorities?.name !== "ROLE_ADMIN" &&
      user?.authorities?.name !== "ROLE_USER"
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollBy(0, -document.documentElement.scrollTop);
  }, [location.pathname, location.search]);

  const hiddenOverlay = () => {
    setOverlay(!overlay);
  };

  return (
    <div className="layout_app w-full h-auto overflow-x-hidden">
      <HeaderManager onClose={() => setOverlay(!overlay)} />
      <div className="content w-full flex lg:mt-[73px] mt-[63px] relative">
        <div className="hidden lg:block w-1920:w-[15%] xl:w-1/5 lg:w-[23%]  w-[15%] h-auto border-r border-border-box sticky top-0 left-0">
          <SiderightContainer />
        </div>

        <div className="sc>768:w-full xl:w-[80%] lg:w-[77%] w-1920:w-[85%] w-full h-auto  relative  lssm:px-[24px] md:px-[80px] 2xl:px-[130px]">
          <Outlet />
          <div className="w-full sc>768:hidden h-[500px] user-background"></div>
        </div>

        {/* navoverlay */}

        {overlay && (
          <div className="overlay-nav z-[15] lg:hidden absolute w-full h-screen  left-0 top-0 flex justify-start">
            <div
              className="h-full lg:w-1/4 pb-[120px] md:w-2/4 sm-480:w-3/4 sm-390:w-11/12 w-full items-center justify-center bg-white border-t border-t-border-box
        "
            >
              <SiderightContainer onClickFunc={hiddenOverlay} />
            </div>
          </div>
        )}

        {/*  */}
      </div>

      <Footer />
    </div>
  );
}

export default LayoutManager;
