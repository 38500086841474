import {  Candidate, Option, Recruit, ResponsiveData } from './../types';
import HttpService from "../configs/api";
import getApi from "./getApi";


const careerService = {
    create: (data:Recruit):Promise<Recruit> => {
            const url = getApi("recruit");
            return HttpService.axiosClient.post(url, data )
    },
    getCareer: (option:Option):Promise<ResponsiveData<Recruit>> => {
        const url = getApi("recruits");
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}})
    },
    getById: (id:number):Promise<Recruit> => {
        const url = getApi(`recruit/${id}`);

        return HttpService.axiosClient.get(url);
    },
    update: (data:Recruit):Promise<Recruit> => {
        const url = getApi("recruit");

        return HttpService.axiosClient.put(url, data)

    },
    delete: (id:number):Promise<boolean> => {
        const url = getApi("recruit/"+id);

        return HttpService.axiosClient.delete(url)
    }
    ,
    postCandidate: (id:number,data:Candidate):Promise<Candidate> => {

        const url = getApi(`candidate/${id}`);
        return HttpService.axiosClient.post(url, data);
    },getCandidates: (option:Option):Promise<ResponsiveData<Candidate>> => {
        const url = getApi("candidates")
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}})
    },
    getCandidatesById: (id:number):Promise<Candidate> => {
        const url = getApi(`candidate/detail/${id}`);

        return HttpService.axiosClient.get(url)
    },
    deleteCareer: (id:number):Promise<boolean> => {
        const url = getApi("recruit/"+id)
        return HttpService.axiosClient.delete(url);
    },
    getCareerForFilter: ():Promise<Recruit[]> => {
        const url = getApi("recruit/fillter");
        return HttpService.axiosClient.get(url);
    },
    getCareerIdForFilter: (data:{id:number, option:Option}):Promise<Recruit[]> => {
        const url = getApi("candidates/"+data.id);
        return HttpService.axiosClient.get(url, {params: {page: data.option.page - 1, limit: data.option.limit}});
    },
    search: ({keyword, type, option}:{keyword:string, type:string, option:Option}):Promise<ResponsiveData<Recruit>> => {
        const url = getApi("recruit/search/"+type+"/"+keyword)

        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    }

}

export default careerService;