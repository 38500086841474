import supplierSlice from "./supplier";
import newsSlice from "./news";
import contactSlice from "./contact";
import ModalSlice from "./modal";
import popupSlice from "./popupSlice";
import authSlice from "./auth";
import userSlice from "./user";
import bannerSlice from "./banner";
import partnerSlice from "./partner";
import careerSlice from "./career";
import translateSlice from "./translate";
import albumSlice from "./album";
import historySlice from "./history";
import DeviceSlice from "./device";
import productSlice from "./products";
import categorySlice from "./categorySlice";
import producDetail from "./ProductDetails";
import searchAllSlice from "./SearchAllSlice";
import  projectSlice  from "./project";
import productPublic from "./productPublic";
import  projectManagerSlice  from "./projectmanagerSlice";

const rootReducer = {
  ModalSlice,
  popupSlice,
  authSlice,
  userSlice,
  bannerSlice,
  partnerSlice,
  careerSlice,
  translateSlice,
  contactSlice,
  albumSlice,
  historySlice,
  newsSlice,
  supplierSlice,
  DeviceSlice,
  productSlice,
  producDetail,
  productPublic,
  categorySlice,
  searchAllSlice,
  projectSlice,
  projectManagerSlice,
};
export default rootReducer;
