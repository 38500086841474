import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PageLoadMore from "../../components/pageLoadMore";
import Pagination from "../../components/Pagination";
import User from "../../containers/Dashboard/User";
import UserSearch from "../../containers/Dashboard/UserSearch";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getUser, searchUserReduce, setCurrentPage } from "../../reducers/user";
const LIMIT = 10
function Users() {
  const [searchParam, setSearchParam] = useSearchParams();

  const [searchUser, setSearchUser] = useState<string>();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const userData = useAppSelector(state => state.userSlice)



  useEffect(()=> {
   
  
    if(!searchUser || searchUser.trim() === "") {
        dispatch(getUser({page: userData.currentPage, limit: LIMIT}))
        
      } else {
        dispatch(searchUserReduce({keyword:searchUser+"", option: {page: userData.currentPage, limit: LIMIT}}))
      }
    
}, [userData.currentPage])

useEffect(()=> {
  if(searchUser !== undefined) {
      dispatch(searchUserReduce({keyword:searchUser+"", option: {page: 1, limit: LIMIT}}))
      setSearchParam({page: "1"})
  }    
}, [searchUser])


  const totalPage = useMemo(()=> {
    return Math.ceil(userData.total/LIMIT)
}, [userData.total])


  return <div className="relative w-full lssm:py-[40px] md:py-[74px]">
        <h2 className="uppercase text-[48px] sc>768:text-px20 text-text-primary font-bold text-center lssm:mb-[24px] md:mb-[40px]">{t("dashboard.user.title")}</h2>
        <UserSearch searchUser={searchUser ?? ""} setSearchUser={setSearchUser}  />
        <div>
          <User />
        </div>
        <div className="mt-[50px]">
              <Pagination currenPage={userData.currentPage} setCurrentPage={setCurrentPage} total={totalPage} limit={window.innerWidth > 767 ? 5 : 2} />             
        </div>

  </div>;
}

export default Users;
