import HttpService from "../configs/api";
import { Device, Option, ResponsiveData } from "../types";
import getApi from "./getApi"

const deviceService = {
    getDevice: (option:Option):Promise<ResponsiveData<Device>> => {
        const url = getApi("devices");

        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}})
    },
    create: (data:Device):Promise<Device> => {
        const url = getApi("device");
        return HttpService.axiosClient.post(url, data)
    },
    update: (data:Device):Promise<Device> => {
        const url = getApi("device");
        return HttpService.axiosClient.put(url, data)
    },
    delete: (id:number):Promise<boolean> => {
        const url = getApi("device/"+id);
        return HttpService.axiosClient.delete(url)
    },
    search: ({keyword, option}:{keyword: string, option:Option}):Promise<ResponsiveData<Device>> => {
        const url = getApi("device/search/"+keyword);
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    }
}

export default deviceService