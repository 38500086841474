import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { deleteDevice } from "../../reducers/device";
import { showModal } from "../../reducers/modal";
import { Device } from "../../types";
import DialogConfirm from "../DialogConfirm";
import DeviceCartModal from "./DeviceCartModal";

type Props = {
  device: Device;
};
function DeviceCart({ device }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const showModalConfirm = () => {
    dispatch(
      showModal(
        <DialogConfirm
          onClick={deleteDeviceMethod}
          message="Bạn có chắc chắn muốn xóa thiết bị này không ?"
        />
      )
    );
  };

  const deleteDeviceMethod = () => {
    dispatch(deleteDevice(parseInt(device.id + "")));
  };

  return (
    <div className="w-1920:w-1/4 m992:w-1/3 sm-480:w-2/4 w-full px-[14px] mb-8">
      <div className="w-full rounded border border-border-gray">
        <div className="w-full rounded">
          <img
            src={device?.topicImage?.imageUrl ?? ""}
            alt="device"
            className="w-full h-[300px]"
          />
        </div>
        <p className="my-[18px] font-bold lssm:text-px16 md:text-px20 text-primary text-center uppercase line-clamp-1">
          {isEnglish ? device.descriptionVi : device.descriptionEn}
        </p>
        <div className="flex items-center flex-wrap bg-bg-lightgray">
          <button
            onClick={() =>
              dispatch(showModal(<DeviceCartModal device={device} />))
            }
            className="w-[49%] cursor-pointer py-4 flex items-center justify-center border-r-2 border-r-border-gray"
          >
            <span className="text-text-primary text-px24">
              <FaEdit />
            </span>
            <span className=" text-primary ml-2 text-base font-normal">
              {t("button.edit")}
            </span>
          </button>
          <hr className="w-[1%] h-[90%] " />
          <button
            onClick={showModalConfirm}
            className="w-2/4 py-4 flex justify-center cursor-pointer"
          >
            <span className="text-text-red text-px24">
              <MdDeleteOutline />{" "}
            </span>
            <span className=" text-text-red ml-2 text-base font-normal">
              {t("button.delete")}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeviceCart;
