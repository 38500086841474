import Banner from "../containers/News/Banner";
import ListNews from "../containers/News/ListNews";


export default function NewsPage() {

    // commencode
    return (
        <div className="">
           <Banner />
           <div className="mt-[133px] sc>768:mt-[30px]">
             <ListNews />
            </div>
        </div>
    )
}