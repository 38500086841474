import { Candidate, Option, Recruit, ResponsiveData } from './../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, debounce, select } from 'redux-saga/effects';
import { deleteCareer, deleteCareerFail, deleteCareerSuccess, getCandidateFilter, getCandidateFilterFail, getCandidateFilterSuccess, getCandidates, getCandidatesFail, getCandidatesSuccess, getCareerFail, getCareerReduce, getCareerSuccess, searchCareer, searchCareerFail, searchCareerSuccess, setCurrentPage, updateCareer, updateCareerFail, updateCareerSuccess } from '../reducers/career';
import careerService from '../services/career';
import { pushPopup } from '../reducers/popupSlice';
import { hideModal } from '../reducers/modal';
import { RootState } from '../configs/redux/store';
import { setCurrenPage } from '../reducers/productPublic';
import { TranslateState } from '../reducers/translate';




function* getCareerMethod ({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<Recruit> = yield call(careerService.getCareer, payload);
        yield put(getCareerSuccess(res))


    }catch(error) {
        yield put(getCareerFail());
        
    }

}


function* updateCareerMethod({payload}:PayloadAction<Recruit>) {
    try {
        const res:Recruit = yield call(careerService.update, payload);
        yield put(updateCareerSuccess(res));

    }catch(error) {
        yield put(updateCareerFail());
        
    }


}

function* getCandidatesSaga({payload}:PayloadAction<Option>) {
    try {
        const res:ResponsiveData<Candidate> = yield call(careerService.getCandidates, payload);
        yield put(getCandidatesSuccess(res));

    }catch(error) {
        yield put(getCandidatesFail())
    }

}

function* getCandidateFilterSaga({payload}:PayloadAction<{id:number, option:Option}>) {
    try {
        const res:ResponsiveData<Candidate> = yield call(careerService.getCareerIdForFilter, payload);
        yield put(getCandidateFilterSuccess(res));
    }catch(error) {
        yield put(getCandidateFilterFail());

    }
}


function* searchCareerSaga({payload}:PayloadAction<{keyword:string, type:string, option:Option}>) {
    try {
        if(payload.keyword.trim() !== "") {
            const res:ResponsiveData<Recruit> = yield call(careerService.search, payload);
            yield put(searchCareerSuccess(res));

        }else {
            yield put(getCareerReduce(payload.option));
        }
    }catch(error) {
        yield put(searchCareerFail());

    }
}

function* deleteCareerSaga({payload}:PayloadAction<number>) {
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);  

    try {
        const res:boolean  = yield call(careerService.delete, payload);
        if(res === true) {
            const {listCareer, currentPage}= yield select((state:RootState) => state.careerSlice);
            if(listCareer.length === 1 && currentPage !== 1 ) {
                yield put(setCurrentPage(currentPage - 1))
            }else {
                    yield put(getCareerReduce({
                        page: currentPage,
                        limit: 10
                    }))
            }
            yield put(deleteCareerSuccess(payload));
            yield put(pushPopup({
                message: translate.isEnglish ? "Xóa thành công" : "Delete successfully",
                type: "SUCCESS"
            }))
            yield put(hideModal())
        }else {
            yield put(deleteCareerFail())
            yield put(pushPopup({
                message: translate.isEnglish ? "Xóa thất bại" : "Delete failed",
                type: "ERROR"
            }))
            yield put(hideModal())

        }
    }catch(error) {
        yield put(deleteCareerFail())
        yield put(pushPopup({
            message: translate.isEnglish ? "Xóa thất bại" : "Delete failed",
            type: "ERROR"
        }))
        yield put(hideModal())
    }
}



export default function* careerSaga() {
    yield takeLatest(getCareerReduce.type, getCareerMethod)
    yield takeLatest(updateCareer.type, updateCareerMethod)
    yield takeLatest(getCandidates.type, getCandidatesSaga)
    yield takeLatest(getCandidateFilter.type, getCandidateFilterSaga)
    yield takeLatest(deleteCareer.type, deleteCareerSaga)
    yield debounce(1000,searchCareer.type, searchCareerSaga)
}