import { all } from "redux-saga/effects";
import authSaga from "./auth";
import userSaga from "./user";
import bannerSaga from "./banner";
import partnerSaga from "./partner";
import careerSaga from "./career";
import contactSaga from "./contact";
import albumSaga from "./album";
import historySaga from "./history";
import newsSaga from "./news";
import deviceSaga from "./device";
import ProductSagaMid from "./products";
import categorySage from "./category";
import ProductDetailSagaMid from "./productDetail";
import searchSagaMid from "./searchAll";

import projectSaga from "./project";
import prouductPublicSagaMid from "./productPublicSaga";
import projectManagerSagaMid from "./projectmanager";

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    bannerSaga(),
    partnerSaga(),
    careerSaga(),
    contactSaga(),
    albumSaga(),
    historySaga(),
    newsSaga(),
    deviceSaga(),
    ProductSagaMid(),
    ProductDetailSagaMid(),
    prouductPublicSagaMid(),
    categorySage(),
    searchSagaMid(),
    projectSaga(),
    projectManagerSagaMid(),
  ]);
}
