import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { HistoryState, LoginPayload, Option, ResponsiveData, User } from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface HistoryStateSlice {
 histories: HistoryState[] ,
 historyPublic: HistoryState[] ,
 isLoading: boolean,
 total: number,
 currentPage: number
}

// Define the initial state using that type
const initialState: HistoryStateSlice = {
    histories: [],
    historyPublic: [],
    isLoading: false,
    total: 2,
    currentPage: 0

}

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload;
    }
    ,
    getHistory: (state, action:PayloadAction<Option>) => {
        state.isLoading = true
    },
    getHistorySuccess: (state, action:PayloadAction<ResponsiveData<HistoryState>>) => {
        state.histories =  [...state.histories,...action.payload.list];
        state.total = action.payload.total;
        state.isLoading = false
    } ,
    getHistoryFail: (state)=> {
        state.isLoading = false
    },
    createHistory: (state, action:PayloadAction<HistoryState>) => {
        state.isLoading = true
    },
    createHistorySuccess: (state, action:PayloadAction<HistoryState>) => {
        state.histories = [action.payload, ...state.histories];
        state.isLoading = true;

    },
    createHistoryFail: (state) => {
        state.isLoading = false
    },
    getHistoryPublic: (state) => {
        state.isLoading = true

    },
    getHistoryPublicSuccess: (state, action:PayloadAction<ResponsiveData<HistoryState>>) => {
        state.historyPublic = action.payload.list;
        state.isLoading = false

    },
    getHistoryPublicFail: (state) => {
        state.isLoading = false

    },
    putHistory: (state, action:PayloadAction<HistoryState>) => {
        state.isLoading = true
    },
    putHistorySuccess: (state, action:PayloadAction<HistoryState>) => {
        state.isLoading = false
        const index = state.histories.findIndex(item => item.id === action.payload.id);
        if(index !== -1) {
            state.histories.splice(index, 1, action.payload)
        }
    },
    putHistoryFail: (state) => {
        state.isLoading = false
    },
    deleteHistory: (state, action:PayloadAction<number>) => {
        state.isLoading = true;
    },
    deleteHistorySuccess: (state, action:PayloadAction<number>) => {
        const index = state.histories.findIndex(item => item.id === action.payload);
        if(index !== -1) {
            state.histories.splice(index, 1)
        }
        state.isLoading = false
    },
    deleteHistoryFail: (state) => {
        state.isLoading = false;
    }
  },
})

export const {getHistory, getHistoryFail, getHistorySuccess, 
    createHistory, createHistoryFail, createHistorySuccess, 
    getHistoryPublic, getHistoryPublicFail, getHistoryPublicSuccess,
    setCurrentPage,
    putHistory,putHistoryFail,putHistorySuccess,
    deleteHistory, deleteHistoryFail, deleteHistorySuccess
} = historySlice.actions

export default historySlice.reducer