
import { useTranslation } from 'react-i18next';
import ManagerPartner from '../../containers/Dashboard/Home/ManagerPartner';
import ManagerBanner from './../../containers/Dashboard/Home/ManagerBanner';
export default function Home () {

  const [t] = useTranslation();

    return <div>
           <h2 className="uppercase text-[48px] sc>768:text-px20 my-[74px] text-text-primary font-bold text-center">{t("dashboard.home.title")}</h2>
            <ManagerBanner />
            <ManagerPartner />
          </div>
}