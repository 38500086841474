import { AnyAction } from "@reduxjs/toolkit";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hook";

type Props = {
    setCurrentPage: (page: number) => AnyAction
    currentPage: any
}


export default function PageLoadMore({setCurrentPage, currentPage}:Props) {
    const [searchParams, setSetParams] = useSearchParams()
    const dispatch = useAppDispatch();

    const location = useLocation();

    const incrementPage = ()=> {
        dispatch(setCurrentPage(currentPage + 1))
        setPage(currentPage + 1);

    }

    const setPage = (page:number)=> {
        const sort = searchParams.get("sort")
        const search = searchParams.get("q");
        if(sort !== null) {

            setSetParams({page: page+"", sort: sort + "" })
        }else {
            setSetParams({page: page+""})

        }
        if(location.pathname === "/search") {
            if(search !== null) {
                setSetParams({q: search, page: page+"" })
    
            }else {
                setSetParams({q: search+""})
    
            }

        }


        setCurrentPage(page as any);
    }

    return (
        <div onClick={incrementPage} className="lssm:w-[200px] h-[30px] lssm:text-px12 sm:text-px16 sm:w-[300px] sm:h-[50px] border-[1px] border-solid border-border-color flex justify-center items-center cursor-pointer">
                Xem Thêm
        </div>
    )
}