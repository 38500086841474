import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next"
import NewsItem from "../../components/NewsItem";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getNews, setCurrentPage } from "../../reducers/news";

const LIMIT = 10

export default function ListNews() {


    const dispatch = useAppDispatch();
    const newsData = useAppSelector(state => state.newsSlice); 
    const translate = useAppSelector(state => state.translateSlice)

    useEffect(() => {
        dispatch(getNews({
            page: newsData.currentPage,
            limit: LIMIT
        }))
    }, [newsData.currentPage])

    const total = useMemo(()=> {
        return Math.ceil(newsData.total/LIMIT);
    }, [newsData.total])

    const [t] = useTranslation();
    return (
        <div className="2xl:px-[162px] mb-[100px] sc991:px-[24px] sc<992:px-[24px]">
               <h2 className="text-text-primary lssm:text-px20 md:text-[48px] font-bold text-center lsm-320:mb-[30px]  lg:mb-[133px]">{t("news.title")}</h2>
               <div className="grid sc<992:grid-cols-2 sc991:grid-cols-1 text-text-gray sc<992:gap-x-6 xl:gap-x-9 w-1920:gap-x-[45px]">
              {
                newsData.newsList.length > 0 ? 
                newsData.newsList.map((news) => {
                    return  <NewsItem key={news.id} news={news} />
                })
                
                
                
                : 
                <div className="h-[300px] flex justify-center items-center w-full col-span-2">{translate.isEnglish ? "Không có dữ liệu" : "No thing"}</div>
              }
              

               </div>

               <div className="mt-[50px]">
                   <Pagination currenPage={newsData.currentPage} setCurrentPage={setCurrentPage} total={total} />
                </div>
               
                   

        </div>
    )
}