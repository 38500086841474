import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LoginPayload, News, Option, ResponsiveData, User } from '../types'
// import {ModalState} from "../types"

// Define a type for the slice state
interface NewsState {
    newsList: News[],
    newsActiveList: News[];
    total: number
    isLoading: boolean
    currentPage: number
}

// Define the initial state using that type
const initialState: NewsState = {
    newsList: [],
    newsActiveList: [],
    isLoading: false,
  total: 0,
  currentPage: 1,
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setCurrentPage: (state, action:PayloadAction<number>) => {
        state.currentPage = action.payload;
    },
    getNews: (state, action:PayloadAction<Option>) => {
        state.isLoading  = true
    },
    getNewsActive: (state) => {
      
    },
    getNewsActiveSuccess: (state, action:PayloadAction<News[]>) => {
        state.newsActiveList = action.payload;
    },
    getNewsSuccess: (state, action:PayloadAction<ResponsiveData<News>>)=> {
        state.newsList = action.payload.list
        state.total = action.payload.total
        state.isLoading = false
    },
    getNewsFail: (state) => {
        state.isLoading = false
    },
    deleteNews: (state, action:PayloadAction<number[]>) => {
        state.isLoading = true
    },
    deleteNewsSuccess : (state, action:PayloadAction<number[]>) => {
       action.payload.forEach(id => {
            const index = state.newsList.findIndex(item => item.id === id);
            if(index !== -1) {
                state.newsList.splice(index, 1);
            }
       })
       state.isLoading = false
    },
    deleteNewsFail :(state)=> {
        state.isLoading = false
    },
    searchNews: (state, action:PayloadAction<{keyword:string, option:Option}>) => {
        state.isLoading = true;
    },
    searchNewsSuccess: (state, action:PayloadAction<ResponsiveData<News>>) => {
        state.newsList = action.payload.list
        state.total = action.payload.total
        state.isLoading = false;
    },
    searchNewsFail: (state) => {
        state.isLoading = false
    }
  
  },
})

export const {getNews, getNewsSuccess, getNewsFail, deleteNews,
     deleteNewsFail, deleteNewsSuccess,getNewsActive, getNewsActiveSuccess,
     setCurrentPage, searchNews, searchNewsFail, searchNewsSuccess
    } = newsSlice.actions

export default newsSlice.reducer