import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function Layout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollBy(0, -document.documentElement.scrollTop);
  }, [location.pathname, location.search]);
//lssm:h-[60px] md:h-[80px] xl:h-[100px] 2xl:h-[128px]
  return (
    <div className="layout_app w-full h-auto overflow-x-hidden">
      <Header />
      <div className="content w-full 2xl:mt-[128px] xl:mt-[100px] m992:mt-[80px] mt-[60px] min-h-[450px]">

        <Outlet />
      </div>

      <Footer />
    </div>
  );
}

export default Layout;
