import clsx from "clsx";

type Props = {
  children: any;
  color: "primary" | "empty";
  onClick?: () => void;
  className?: string;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  ref?: any
};

export default function Button({
  children,
  color,
  onClick,
  className,
  disabled,
  type,
  ref
}: Props) {
  return (
    <button
    ref={ref}
      disabled={disabled}
      type={type ? type : "button"}
      onClick={onClick}
      className={clsx(
        "btn text-px16",
        { "btn-primary": color === "primary", "btn-empty": color === "empty" },
        className ?? ""
      )}
    >
      {children}
    </button>
  );
}
