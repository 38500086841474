import { ConsoleSqlOutlined } from "@ant-design/icons";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { projecResult, projecType, searchProjecParam } from "../typeProps/Productype";
import { Option, Project } from "../types";

interface projectInterface {
    loadding: boolean;
    error:null | string;
    total: number;
    currentPage: number;
    projectList: projecType[]
}

const initialState: projectInterface= {
    loadding:false,
    error:null,
    total: 0,
    currentPage: 1,
    projectList: []
}

export const  projectManagerSlice = createSlice({
    name: "projectManagerSlice",
    initialState,
    reducers: {
        setCurrenPage(state,action: PayloadAction<number>){
            state.currentPage = action.payload;
        },
        getProject(state,action: PayloadAction<Option>){
            state.loadding= true;
        },
        getProjectSuccess (state,action:PayloadAction<projecResult>){
            state.total = action.payload.total;
            state.projectList = action.payload.list;
            state.loadding = false;
            
        },
        getProjectFail(state,action:PayloadAction<string>){
            state.error = action.payload;
        },
        searchProject(state,action:PayloadAction<searchProjecParam>){
            state.loadding = true;
        },
        searchProjectSuccess(state,action:PayloadAction<projecResult>){
            state.total = action.payload.total;
            state.projectList = action.payload.list;
            state.loadding = false;
        },
        editProject (state,action:PayloadAction<Project>){
           
        },
        editProjecSuccess(state,action:PayloadAction<projecType>){
            const newProject = state.projectList.map((item,index)=> {
                if(item.id === action.payload.id){
                    item = action.payload
                }
                return item;
            })
            state.projectList = newProject
        },
        putPriorityProject (state,action:PayloadAction<number>){
        },
        putPrioritySuccess(state,action:PayloadAction<projecType>){
            const newState = state.projectList.map((item,index)=> {
                if(item.id === action.payload.id){
                    item = action.payload
                }
                return item
            })
            state.projectList = newState;
        }
    }
});


export const {setCurrenPage, getProject,getProjectSuccess,getProjectFail,searchProject,searchProjectSuccess,editProject,editProjecSuccess,putPriorityProject,putPrioritySuccess} = projectManagerSlice.actions;

export default projectManagerSlice.reducer;