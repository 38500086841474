import HttpService from "../configs/api";
import { UploadFile, UrlResponsive } from "../types";
import getApi from "./getApi"


const uploadService = {
    upload: (data:any):Promise<UrlResponsive> => {
        const url = getApi("upload-image");
        return HttpService.axiosClient.post(url, data)
    },
    uploadFile: (data:any):Promise<UploadFile> => {
        const url = getApi("upload-file");

        return HttpService.axiosClient.post(url, data);
    },
    downloadFile: (filePath:string) => {
        const url = getApi("download-file");
        return HttpService.axiosClient.get(`${url}?file=${filePath}`)
    }
}

export default uploadService 