import HttpService from "../configs/api";
import { Contact, FormContact, Option, ResponsiveData } from "../types";
import getApi from "./getApi"


const contactService = {
    postContact: (data:Contact):Promise<Contact> => {
        const url = getApi("contact");
        return HttpService.axiosClient.post(url, data)
    },
    getContacts: (option:Option)=> {
        const url = getApi("contacts");

        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, size: option.limit}})
    },
    getContactById: (id:string):Promise<Contact> => {
        const url = getApi(`contact/${id}`);
        return HttpService.axiosClient.get(url);

    },
    feedBackContact: (data:any):Promise<Contact> => {
        const url = getApi("contact");
        return HttpService.axiosClient.put(url, data)
    },
    deleteContact: (id:number):Promise<boolean> => {

        const url =getApi(`contact/${id}`)
        return HttpService.axiosClient.delete(url);
    },
    createFormContact: (data:any):Promise<FormContact> => {
        const url = getApi("supplier");

        return HttpService.axiosClient.post(url, data);
    },
    getFormContact: (option:Option):Promise<ResponsiveData<FormContact>> => {
        const url = getApi("suppliers");
        return HttpService.axiosClient.get(url, {params: {page: option.page, limit: option.limit}})
    },
    editFormContact: (data:any):Promise<FormContact> => {
        const url = getApi("supplier");
        return HttpService.axiosClient.put(url, data)
    },
    deleteFormContact: (id:number):Promise<boolean> => {
        const url = getApi("supplier/"+id);
        return HttpService.axiosClient.delete(url)
    },
    search: ({keyword, option}:{keyword:string, option:Option}):Promise<ResponsiveData<Contact>> => {
        const url = getApi("contact/search/"+keyword)
        return HttpService.axiosClient.get(url, {params: {page: option.page - 1, limit: option.limit}});
    }
}


export default contactService