import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../configs/redux/store'

export type ListPopUpState = {
    id?: string
    message: string
    type: "SUCCESS" | "ERROR" | "WARNING"
}

interface ModalState {
    lisMessages: ListPopUpState[]
}


const initialState: ModalState = {
    lisMessages: []
}

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    pushPopup: (state, action:PayloadAction<ListPopUpState>) => {
        const data = {
            ...action.payload,
            id: Date.now() + (Math.random() * 100) + ""
        }
     
        if(state.lisMessages.length >= 2) {
            state.lisMessages.shift();
        }
        state.lisMessages.push(data);
    },
    popPopUp: (state, action:PayloadAction<string>) => {
        const id = action.payload
        state.lisMessages = state.lisMessages.filter(item => item.id !== id);
    }

  },
})

export const { pushPopup, popPopUp } = popupSlice.actions

export default popupSlice.reducer